
// slick-arrow 줄바꿈  처리
body {
	.slick-arrow {
		word-break: normal;
		word-wrap: normal;
		/* LGECI-297 20201126 add */
		overflow: hidden;
		&:not([disabled]):focus, &:not([disabled])::-moz-focus-inner {
			outline: 2px solid #000;		
			outline-offset: -2px;
		}
		/* //LGECI-297 20201126 add */
	}
}

// Carousel Buttons
.carousel-btn-wrap {
	// square style
	.type-square {
		appearance: none;
		border: 1px solid $line-gray;
		background-color: $bg-white;
		border-radius: 2px;
		text-indent: -9999em;
		// font-size: 0;
		line-height: 0;
		vertical-align: top;
		box-sizing: border-box;
		padding: 0;
		color: $color-gray;

		background-position: 50% 50%;
		background-repeat: no-repeat;

		&.slick-pause,
		&.slick-play {
			border: none;
		}
		&.slick-pause {
			background-image: url(/lg5-common-gp/images/common/icons/pause-dimgray.svg);
		}
		&.slick-play {
			background-image: url(/lg5-common-gp/images/common/icons/play-dimgray.svg);
		}
		&.slick-prev {
			background-image: url(/lg5-common-gp/images/common/icons/arrow-left-dimgray.svg);
			background-image: url(/lg5-common-gp/images/common/icons/arrow-left.svg);
		}
		&.slick-next {
			background-image: url(/lg5-common-gp/images/common/icons/arrow-right-dimgray.svg);
			background-image: url(/lg5-common-gp/images/common/icons/arrow-right.svg);
		}
		@include screen(custom, max, $max-sm) {
			width: 20px;
			height: 20px;
			&.slick-prev,
			&.slick-next {
				background-size: auto 16px;
			}
			&.slick-pause,
			&.slick-play {
				margin: 0 17px;
			}
		}
		@include screen(custom, min, $min-md) {
			width: 26px;
			height: 26px;
			&.slick-pause,
			&.slick-play {
				margin: 0 12px;
			}
		}

		&:disabled {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(255,255,255,0.7);
			}
		}
	}
}

// thumbnail carousel
.thumbnail-carousel {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	box-sizing:content-box;
	margin:0;
	padding:0;
	padding-bottom:13px;
	overflow:hidden;
	.slick-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}
	.slick-list {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
		padding: 0;
		width:100%;
		height:100%;
		&:focus {
			outline: none;
		}
		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
		.slick-track {
			float:left;
			// 20200525 START 박지영 : rtl 오류 수정
			[dir='rtl'] & {float:right;}
			// 20200525 END
			.slick-slide {
				float:left;
				[dir='rtl'] & {float:right;}
			}
		}
	}
	ul.slick-dots {
		position:absolute !important;
		bottom:0 !important;
		left:0 !important;
		width:100% !important;
		height:7px !important;
		text-align:center !important;
		li {
			position:relative;
			padding:0 !important;
			margin:0 2px !important;
			display:inline-block !important;
			float:none !important;
			width:7px !important;
			height:7px !important;
			line-height:normal;
			vertical-align:top;
			&:first-child {
				margin-left:0;
			}
			a, button {
				border-radius:50%;
				overflow:hidden;
				background: $color-white;
				border:1px solid $color-gray;
				width:7px;
				height:7px;
				margin:0;
				padding:0;
				display:block;
				cursor:pointer;
				color:#fff;
				&:focus {
					outline:1px solid $color-black;
				}
			}
			&.slick-active {
				a, button {
					background:$color-gray;
					border-color:$color-gray;
					color:$color-gray;
					&:focus {		
						outline: 2px solid #000 !important;/* LGECI-297 : 20201214 edit */
					}
				}
			}
			&:before {
				display:none !important;
			}
		}
	}
}

// [Start] LGEGMC-2853
.keen-slider-wrap {
	.keen-slider {
		//fade effect
		&.fade-effect {
			display: block !important;
			.keen-slider__slide {
				position: absolute !important;
				z-index: -1;
				transition: all 0.5s;
				opacity: 0;
				&.slick-current {
					position: relative !important;
					opacity: 1;
					z-index: 1;
				}
			}
		}
		//blink effect
		&.blink-effect {
			display: block !important;
			.keen-slider__slide {
				position: absolute !important;
				z-index: -1;
				&.slick-current {
					position: relative !important;
					z-index: 1;
				}
			}
		}
	}
}
// [End] LGEGMC-2853