@charset "UTF-8";

.header-notice-popup {
    display: none;
    margin: 0 -15px;
	background-color: $bg-whitesmoke;
	border-top: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;
    text-align: center;
    &.active {
        display: block;
    }
    .notice-inner {
        position: relative;
        margin: 0 auto;
        padding: 30px 24px 33px;
        max-width: 1600px;
        @include screen(custom, max, $max-sm) {
            padding-top: 40px;
            padding-bottom: 30px;
        }
    }
	.text {
		margin-bottom: 21px;
		font-size: 20px;
		line-height: 26px;
		color: $color-black;
		@include font-family($font-semibold);
        @include screen(custom, max, $max-sm) {
            font-size: 17px;
            line-height: 24px;
        }
	}
    .btn-hide-section {
        position: absolute;
        top: 13px;
        right: 35px;
        width: 20px;
        height: 20px;
        border: none;
        background: url(/lg5-common-gp/images/common/icons/popover-close.svg) no-repeat 50% 50%;
        background-size: 12px 12px;
        cursor: pointer;
        opacity: .75;
        @include screen(custom, max, $max-sm) {
            right: 13px;
        }
        &:hover, &:focus {
            opacity: 1;
        }
        [dir="rtl"] & {
            left: 35px;
            right: auto;
            @include screen(custom, max, $max-sm) {
                left: 13px;
            }
        }
    }
    .m-full {
        @include screen(custom, max, $max-sm) {
            width: 100%;
        }
    }
}