.gnb-notice-banner-wrap {
	position: relative;
	z-index:10000;
	margin-right: -15px;
	margin-left: -15px;
	background-color: $bg-whitesmoke;
	border-bottom: 1px solid $line-gray;
	.inner {
		position: relative;
		margin: 0 auto;
		max-width: 1600px;
	}
	.banner-box {
		display: flex;
		padding: 0 75px;
		text-align: center;
		height: 50px;
		align-items: center;
		justify-content: center;
		@include screen(custom, max, $max-sm) {
			display: block;
			text-align: justify;
			padding: 17px 45px 17px 19px;
			height: auto;
			min-height: 50px;
			[dir="rtl"] & {
				padding: 17px 19px 17px 45px;
			}
		}
		.text {
			margin: 0;
			font-weight: 700;
			font-size: 14px;
			color: $color-black;
			@include screen(custom, max, $max-sm) {
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				line-height: 18px;
				max-height: 36px;
				font-weight: 600;
				[dir="rtl"] & {
					display: block;
				}
			}
			a {
				text-decoration: underline;
			}
		}
		.btn-area {
			margin-left: 10px;
			[dir="rtl"] & {
				margin-left: 0;
				margin-right: 10px;
			}
			@include screen(custom, max, $max-sm) {
				display: block;
				margin: 10px 0 0 0 !important;
				height: 28px;
			}
			.btn {
				padding: 5px 10px;
			}
		}
	}
	.btn-banner-close {
		position: absolute;
		top: 50%;
		right: 32px;
		width: 29px;
		height: 29px;
		border: 0;
		background: url(/lg5-common-gp/images/common/icons/popover-close.svg) no-repeat 50% 50%;
		background-size: 12px;
		cursor: pointer;
		transform: translateY(-50%);
		[dir="rtl"] & {
			right: auto;
			left: 32px;
		}
		@include screen(custom, max, $max-sm) {
			top: 0;
			right: 0;
			background-size: 9px;
			transform: translateY(0);
			[dir="rtl"] & {
				right: auto;
				left: 0;
			}
		}
	}
}
 // LGEITF-531 : 분기점 1199 로 변경
.gnb-standard-banner-wrap {
	margin-right: -15px;
	margin-left: -15px;
	background-color: $bg-whitesmoke;
	border-bottom: 1px solid $line-gray;
	&.opt-fade {
		@include screen(custom, max, 1199) {
			background-color: initial;
			border-bottom: none;
		}
	}
	.inner {
		position: relative;
		margin: 0 auto;
		max-width: 1600px;
		z-index: 10; //[LGEJP-787]
	}
	.carousel-btn-wrap {
		text-align: center;
		position: absolute;
		right: 68px;
		top: 13px;
		border: 1px solid $line-gray;
		border-radius: 12px;
		background-color: $bg-whitesmoke;
		line-height: 1;
		font-size: 0;
		z-index: 1000;
		[dir="rtl"] & {
			right: auto;
			left: 68px;
		}
		@include screen(custom, max, 1199) {
			right: 72px;
			top: 10px;
			[dir="rtl"] & {
				right: auto;
				left: 72px;
			}
		}
		@include screen(custom, max, $min-md) {
			right: 42px;
			[dir="rtl"] & {
				right: auto;
				left: 42px;
			}
		}
		.type-square {
			margin: 0 !important;
			border: 0;
			background-color: $bg-whitesmoke;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			cursor: pointer;
			font-size: 1px;
			&.slick-prev {
				background-size: auto 16px;
				[dir="rtl"] & {
					background-image: url(/lg5-common-gp/images/common/icons/arrow-right.svg);
				}
			}
			&.slick-next {
				background-size: auto 16px;
				[dir="rtl"] & {
					background-image: url(/lg5-common-gp/images/common/icons/arrow-left.svg);
				}
			}
			&.slick-pause {
				background-size: auto 10px;
			}
			&.slick-play {
				display: none;
				background-size: auto 10px;
				[dir="rtl"] & {
					transform: rotateY(180deg);
				}
			}
		}
		.banner-count {
			position: absolute;
			left: -40px;
			top: 0;
			white-space: nowrap;
			width: 30px;
			font-size: 14px;
			line-height: 22px;
			font-weight: 600;
			color: $color-nightrider;
			[dir="rtl"] & {
				left: auto;
				right: -40px;
			}
		}
	}
	.paused {
		.carousel-btn-wrap {
			.type-square {
				&.slick-pause {
					display: none;
				}
				&.slick-play {
					display: inline-block;
				}
			}
		}
	}
	.standard-banner-list {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 50px;
		@include screen(custom, max, 1199) {
			height: 89px;
		}
		&.slick-initialized {
			height: auto;
		}
		.banner-item {
			display: flex;
			align-items: center;
			@include screen(custom, max, 1199) {
				display: block;
			}
		}
		.banner-box {
			display: flex;
			padding: 0 185px 0 40px;
			width: 100%;
			height: 50px;
			text-align: center;
			align-items: center;
			justify-content: center;
			[dir="rtl"] & {
				padding: 0 40px 0 185px;
			}
			@include screen(custom, max, 1199) {
				position: relative;
				display: block;
				text-align: justify;
				padding: 41px 30px 12px 30px;
				height: auto;
				[dir="rtl"] & {
					padding: 41px 30px 12px 30px;
				}
			}
			@include screen(custom, max, $min-md) {
				padding-left: 19px;
				[dir="rtl"] & {
					padding-right: 19px;
				}
			}
			&.no-title {
				@include screen(custom, max, 1199) {
					// padding-top: 12px; // LGEGMC-3710
				}
			}
			.title {
				margin-right: 10px;
				font-size: 15px;
				font-weight: 700;
				color: $color-carmine;
				line-height: 1.2;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				[dir="rtl"] & {
					margin-right: 0;
					margin-left: 10px;
				}
				@include screen(custom, max, 1199) {
					position: absolute;
					left: 30px;
					top: 14px;
					font-weight: 600;
					[dir="rtl"] & {
						left: auto;
						right: 30px;
					}
				}
				@include screen(custom, max, $min-md) {
					left: 19px;
					[dir="rtl"] & {
						left: auto;
						right: 19px;
					}
				}
			}
			.icon {
				margin-right: 6px;
				line-height: 1;
				[dir="rtl"] & {
					margin-right: 0;
					margin-left: 6px;
				}
				@include screen(custom, max, 1199) {
					position: absolute;
					left: 30px;
					top: 14px;
					[dir="rtl"] & {
						left: auto;
						right: 30px;
					}
				}
				@include screen(custom, max, $min-md) {
					left: 19px;
					[dir="rtl"] & {
						left: auto;
						right: 19px;
					}
				}
				img, svg {
					height: 22px;
				}
			}
			.text {
				display: inline;
				margin: 0;
				font-weight: 700;
				font-size: 14px;
				color: $color-black;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				line-height: 18px;
				max-height: 36px;
				[dir="rtl"] & {
					display: block;
				}
				@include screen(custom, max, 1199) {
					font-weight: 600;
				}
				a {
					text-decoration: underline;
				}
				// [Start] LGEIN-1012
				&.type-thin{
					font-weight: 400;
					color: #333;
				}
				.first-name{
					font-weight: 700;
				}
				.mo-active{
					display: none;
					@include screen(custom, max, $min-md) {
						display: block;
					}
				}
				// [End] LGEIN-1012
			}
			.btn-area {
				display: inline-block;
				margin-left: 10px;
				[dir="rtl"] & {
					margin-left: 0;
					margin-right: 10px;
				}
				@include screen(custom, max, 1199) {
					display: block;
					margin: 10px 0 0 0 !important;
					height: 28px;
				}
				.btn {
					padding: 4px 20px;
					display: -webkit-inline-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					@include screen(custom, max, $min-md) {
						padding: 4px 10px;
					}
				}
			}
		}
		&.opt-fade {
			.banner-box {
				@include screen(custom, max, 1199) {
					padding-bottom: 20px;
				}
			}
			.banner-item {
				float: left;
				width: 100%;
				[dir="rtl"] & {
					float: right;
				}
			}
			.text {
				@include screen(custom, max, 1199) {
					height: auto;
					max-height: 36px;
				}
			}
			.slick-list {
				overflow: hidden;
				@include screen(custom, max, 1199) {
					background-color: $bg-whitesmoke;
					border-bottom: 1px solid $line-gray;
				}
			}
		}
	}
	.btn-banner-close {
		position: absolute;
		top: 50%;
		right: 32px;
		width: 29px;
		height: 29px;
		border: 0;
		background: url(/lg5-common-gp/images/common/icons/popover-close.svg) no-repeat 50% 50%;
		background-size: 12px;
		cursor: pointer;
		transform: translateY(-50%);
		z-index: 1000;
		[dir="rtl"] & {
			right: auto;
			left: 32px;
		}
		@include screen(custom, max, 1199) {
			top: 7px;
			right: 37px;
			background-size: 9px;
			transform: translateY(0);
			[dir="rtl"] & {
				right: auto;
				left: 37px;
			}
		}
		@include screen(custom, max, $min-md) {
			right: 7px;
			[dir="rtl"] & {
				right: auto;
				left: 7px;
			}
		}
	}
}

