.modal {
	&.modal-vip-referrer-coupon {
		.modal-dialog {
			border: 1px solid #333;
			&.modal-md {
				@include screen(custom, max, $max-sm) {
					margin: 12px;
				}
			}
		}
		.modal-header {
			border-bottom: 0;
			padding: 42px 0 10px !important;
			justify-content: center;
			text-align: center;
			@include screen(custom, max, $max-sm) {
				padding-bottom: 0 !important;
			}
		}
		.modal-footer {
			.btn {
				&:only-child{
					@include screen(custom, max, $max-sm) {
						width:100% !important;
					}
				}
			}
		}
		.coupon-flipped {
			position: relative;
			padding: 17px 67px 17px 25px;
			margin: 0 auto 29px;
			width: 100%;
			max-width: 327px;
			border: 1px solid $line-dimgray;
			border-radius: 5px;
			text-align: center;
			[dir="rtl"] & {
				padding: 17px 25px 17px 67px;
			}
			@include screen(custom, max, $max-sm) {
				padding: 22px 73px 22px 31px;
				margin-bottom: 25px;
				max-width: 292px;
				[dir="rtl"] & {
					padding: 22px 31px 22px 73px;
				}
			}
			&:after {
				display: block;
				position: absolute;
				right: 42px;
				top: 0;
				bottom: 0;
				width: 2px;
				height: 100%;
				background: url(/lg5-common-gp/images/common/icons/coupon-line.gif) repeat-y 0 0;
				z-index: 2;
				content: '';
				[dir="rtl"] & {
					left: 42px;
					right: auto;
				}
			}
			.coupon-info {
				color: $color-nightrider;
				font-size: 14px;
				.coupon-name {
					font-size: 30px;
					color: $color-black;
					@include font-family($font-bold);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.coupon-txt {
					margin-top: 6px;
					padding-top: 10px;
					border-top: 1px solid $line-gray;
					line-height: 1.2em;
					.fc-carmine {
						color: $color-carmine;
					}
				}
			}
			.coupon-bg {
				position: absolute;
				width: 42px;
				right: 0;
				top: 0;
				bottom: 0;
				display: block;
				background-color: #e4e4e4;
				z-index: 5;
				transition: all 0.2s;
				border-radius: 0 5px 5px 0;
				[dir="rtl"] & {
					left: 0;
					right: auto;
					border-radius: 5px 0 0 5px;
				}
				&:after {
					content: '';
					position: absolute;
					width: 12px;
					height: 6px;
					z-index: 5;
					bottom: -1px;
					left: -5.5px;
					background: #fff;
					border: 1px solid #000;
					border-radius: 12px 12px 0 0;
					border-bottom: transparent;
					[dir="rtl"] & {
						left: auto;
						right: -5.5px;
					}
				}
				&:before {
					content: '';
					position: absolute;
					width: 12px;
					height: 6px;
					z-index: 5;
					top: -1px;
					left: -5.5px;
					background: #fff;
					border: 1px solid #000;
					border-radius: 0 0 12px 12px;
					border-top: transparent;
					[dir="rtl"] & {
						left: auto;
						right: -5.5px;
					}
				}
			}
		}
		.notice-area {
			padding: 25px 30px;
			background-color: $bg-whitesmoke;
			@include screen(custom, max, $max-sm) {
				padding: 20px;
			}
			strong {
				display: block;
				font-size: 20px;
				color: $color-black;
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size: 16px;
					color: $color-nightrider;
				}
			}
			.list-sty-dot {
				margin-top: 9px;
				li {
					&:before {
						width: 4px;
						height: 4px;
						background: $bg-carmine;
					}
				}
			}
		}
		.checkbox-box.checkbox-sm {
			margin-top: 6px;
			line-height: 20px;
			.text {
				font-size: 14px;
			}
		}
	}
	&.modal-open-vip-referrer-coupon {
		top: initial;
		.modal-dialog {
			margin: 0;
			padding: 20px;
			transform: translate(-50%, 0);
			vertical-align: bottom;
			@include screen(custom, max, $max-sm) {
				padding: 20px 27px;
			}
		}
		.modal-content {
			display: block;
			padding: 18px 52px;
			background: rgba(0,0,0,.7);
			border-radius: 84px;
			text-align: center;
			color: $color-white;
			@include screen(custom, max, $max-sm) {
				padding: 8px 47px;
			}
			strong {
				display: block;
				margin-bottom: 7px;
				@include font-family($font-bold);
			}
			.link-text {
				color: $color-white;
				&.ico-right {
					&:after {
						background-image:url('/lg5-common-gp/images/common/icons/link-right-white.svg');
					}
				}
			}
			.modal-close {
				top: 50% !important;
				right: 15px !important;
				margin-top: -10px !important;
				background: url('/lg5-common-gp/images/common/icons/link-fold.svg') no-repeat 50% 50% !important;
				[dir="rtl"] & {
					right: auto !important;
					left: 15px !important;
				}
			}
		}
	}
}