@import './svg'; //LGCOMSPEED-6(8th)
// Button
.btn-area {
	// 모바일에서 버튼이 2단으로 나오는 경우
	&.type-double {
		> .btn[class^='btn'] { // 우선순위 상승
			margin:0 0 0 6px;
			&:first-child {margin-left:0;}
			@include screen(custom, max, $max-sm) {
				width:calc(100%/2 - 6px);
				margin:0 0 0 6px;
				&:first-child {margin-left:0;}
			}
		}
		[dir="rtl"] & {
			> .btn[class^='btn'] {
				margin:0 6px 0 0 ;
				&:first-child {margin-right:0;}
				@include screen(custom, max, $max-sm) {
					margin:0 6px 0 0 ;
					&:first-child {margin-right:0;}
				}
			}
		}
	}
}
.btn {
	display:inline-block;
	min-width:80px;
	padding:11px 23px; //LGEGMC-1147 24px > 23px 수정
	border-radius:3px;
	border: 1px solid transparent;
	@extend %uppercase;
	@include font-family($font-bold);
	font-size:14px;
	line-height:16px;
	text-decoration:none;
	text-align: center;
	vertical-align: middle;
	white-space:normal;
	cursor: pointer;
	background-color:transparent;
	&:focus {
		box-shadow:0 0 0 0.1rem #7fbdff !important;
	}
	&.btn-white {
		background:$bg-white!important;
		color:$color-dimgray !important;
		border-color:$line-dimgray !important;
		&:link, &:visited, &:active {
			background:$bg-white!important;
			color:$color-dimgray !important;
			border-color:$line-dimgray !important;
		}
		&:not([disabled]):hover,
		&:not([disabled]):focus {
			background:$bg-white !important;
			color:$color-black !important;
			color:$color-black !important;
		}
	}
	&.btn-primary {
		color:$color-white !important;
		border-color:$line-carmine !important;
		background:$bg-carmine !important;

		&:link, &:visited, &:active {
			color:$color-white !important;
			border-color:$line-carmine !important;
			background:$bg-carmine !important;
		}
		&:not([disabled]):hover,
		&:not([disabled]):focus {
			color:$color-white !important;
			border-color:#cc0041 !important;
			background:#cc0041 !important;
		}
	}
	&.btn-secondary {
		color:$color-white !important;
		border-color:$line-dimgray !important;
		background:$bg-dimgray !important;

		&:link, &:visited, &:active {
			color:$color-white !important;
			border-color:$line-dimgray !important;
			background:$bg-dimgray !important;
		}
		&:not([disabled]):hover,
		&:not([disabled]):focus {
			color:$color-white !important;
			border-color:#4d4d4d !important;
			background:#4d4d4d !important;
		}
	}
	&.btn-outline-primary {
		color:$color-carmine !important;
		border-color:$line-carmine !important;
		background:transparent !important;

		&:link, &:visited, &:active {
			color:$color-carmine !important;
			border-color:$line-carmine !important;
			background:transparent !important;
		}
		&:not([disabled]):hover,
		&:not([disabled]):focus {
			color:#cc0041 !important;
			border-color:#cc0041 !important;
			background:transparent !important;
		}
	}
	&.btn-outline-secondary {
		background:transparent !important;
		color:$color-dimgray !important;
		border-color:$line-dimgray !important;
		&:link, &:visited, &:active {
			background:transparent !important;
			color:$color-dimgray !important;
			border-color:$line-dimgray !important;
		}
		&:not([disabled]):hover,
		&:not([disabled]):focus {
			background:transparent !important;
			color:#000 !important;
			border-color:#000 !important;
		}
	}
	&.btn-outline-light {
		color:$color-carmine !important;
		border-color:$line-white !important;
		background:transparent !important;

		&:link, &:visited, &:active {
			color:$color-carmine !important;
			border-color:$line-white !important;
			background:transparent !important;
		}
		&:not([disabled]):hover,
		&:not([disabled]):focus {
			color:#cc0041 !important;
			border-color:$line-white !important;
			background:transparent !important;
		}
	}
	&.btn-outline-small {
		padding:5px 9px;
		min-width:0;
		text-transform:none;
		color:$color-dimgray;
		border-color:$line-dimgray;
		&.ico-download {
			&:before {
				@extend %linkicon;
				background:url($download-dimgray) no-repeat 0 0; //LGCOMSPEED-11
				margin-right:5px;
			}
			[dir="rtl"] & {
				&:before {
					margin-right:0;
					margin-left:5px;
				}
			}
		}
	}
	&.btn-sm {
		padding:8px 20px 9px;
	}
	&.btn-lg {
		@include screen(custom, min, $min-md) {
			padding:13px 29px 14px;
		}
	}
	&.btn-block {
		display:block;
		width:100%;
	}
	&:link, &:visited, &:hover, &:active, &:focus {
		text-decoration:none;
	}
	&.disabled, &:disabled {
		opacity:0.4;
		cursor: default;
	}
	&.btn-round {
		padding:8px 30px 9px 30px;
		border-radius: 100px;
	}
}

// Text Button
.link-text {
	position:relative;
	display:inline-block;
	color:$color-carmine;
	@include font-family($font-regular);
	font-size:14px;
	line-height:20px;
	text-decoration:underline;
	word-break:break-all;
	cursor: pointer;
	&:focus, &:hover {color:$color-carmine;}
	//&:before {
	//	@include pseudo();
	//	bottom:3px;left:0;width:100%;height:1px;
	//	background:$bg-carmine;
	//}
	&.bold {
		@include font-family($font-bold);
		@extend %uppercase;
		text-decoration:none;
		&:hover, &:focus {text-decoration:underline;}
		//&:before {
		//	display:none;
		//}
		//&:hover:before,
		//&:focus:before {
		//	display:block;
		//}
	}
	&.lg {
		@include screen(custom, min, $min-md){
			font-size:16px;
		}
	}
	//icon
	&.ico-blank {
		//&:before {width:calc(100% - 18px);}
		&:after {
			@extend %linkicon;
			background:url('/lg5-common-gp/images/common/icons/blank-carmine.svg') no-repeat 0 0;
			margin-left:6px;
		}
		[dir="rtl"] & {
			&:after {
				margin-left:0;
				margin-right:6px;
				transform: rotateY(180deg);
				-moz-transform: rotateY(180deg);
				-ms-transform: rotateY(180deg);
				-o-transform: rotateY(180deg);
			}
		}
	}
	&.ico-popup {
		//&:before {width:calc(100% - 18px);}
		&:after {
			@extend %linkicon;
			background:url('/lg5-common-gp/images/common/icons/popup-carmine.svg') no-repeat 0 0;
			margin-left:6px;
		}
		[dir="rtl"] & {
			&:after {
				margin-left:0;
				margin-right:6px;
			}
		}
		&.gray-type{
			&:after {
				@extend %linkicon;
				background:url('/lg5-common-gp/images/common/icons/popup-nightrider.svg') no-repeat 0 0;
				margin-left:6px;
			}
		}

	}
	&.ico-open-send-address{
		//&:before {width:calc(100% - 18px);}
		&:after {
			@extend %linkicon;
			width: 16px;
			height: 20px;
			background:url('/lg5-common-gp/images/common/icons/open-send-address-black.svg') no-repeat 0 0;
		}
	}
	&.ico-right {
		//&:before {width:calc(100% - 15px);}
		&:after {
			@extend %linkicon;
			background:url($link-right-carmine) no-repeat 0 1px; //LGCOMSPEED-11
			margin-left:3px;
		}
		[dir="rtl"] & {
			&:after {
				margin-left:0;
				margin-right:3px;
				transform:rotateY(180deg);
				-moz-transform:rotateY(180deg);
				-ms-transform:rotateY(180deg);
				-o-transform:rotateY(180deg);
			}
		}
	}
	&.ico-download {
		color:$color-dimgray;
		&:after {
			@extend %linkicon;
			background:url($download-dimgray) no-repeat 0 0; //LGCOMSPEED-11
			margin-left:6px;
		}
		[dir="rtl"] & {
			&:after {
				margin-left:0;
				margin-right:6px;
			}
		}
	}
	&.ico-download-carmine {
		color:$color-carmine;
		&.bold {
			text-transform: none;
		}
		&:after {
			width: 14px;
			height: 14px;
			@extend %linkicon;
			background:url('/lg5-common-gp/images/common/icons/download-carmine.svg') no-repeat 0 0;
			margin-left:6px;
			vertical-align: -4px;
			background-size: 100%;
			
		}
		[dir="rtl"] & {
			&:after {
				margin-left:0;
				margin-right:6px;
			}
		}
	}
	&.ico-left-x {
		&:before {
			@extend %linkicon;
			width: 9px;
			height: 9px;
			background:url('/lg5-common-gp/images/common/icons/content-delete.svg') no-repeat 0 0;
			margin-right:6px;
		}
		[dir="rtl"] & {
			&:before {
				margin-right:0;
				margin-left:6px;
			}
		}
	}


	&.ico-wishlist {
		font-size:14px;
		color:$color-dimgray;
		text-decoration: none;
		@include font-family($font-bold);
		&:before {
			@extend %linkicon;
			margin-right:6px;
			width:20px;
			height:20px;
			background:url($wishlist) no-repeat 0 0; //LGCOMSPEED-6(8th)
			vertical-align: top;
		}
		&.active {
			&:before {
				background:url('/lg5-common-gp/images/common/icons/wishlist-on.svg') no-repeat 0 0;
			}
		}
	}

	&.ico-compare {
		font-size:14px;
		color:$color-dimgray;
		text-decoration: none;
		@include font-family($font-bold);
		&:before {
			@extend %linkicon;margin-right:6px;
			[dir=rtl] & {margin-right:0;margin-left:6px;}
			width:20px;
			height:20px;
			background:url($compare) no-repeat 0 0; //LGCOMSPEED-6(8th)
			vertical-align: top;
		}
		.sr-only {
			display: none !important;
		}
		//.remove {display: none;}
		&.added {
			&:before {
				background-image:url('/lg5-common-gp/images/common/icons/compare-added.svg');
			}
			//.add {display: none;}
			//.remove {display: block;}
		}
	}

}
