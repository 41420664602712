@import './svg'; //LGCOMSPEED-6(8th)
.toast-msg {
	position: fixed;
	bottom:20px;
	left:0;
	right:0;
	text-align: center;
	&.fade {
		z-index: -9999;
	}
	&.show {
		z-index: 10001; //LGEGMC-4297
	}
	.toast-content {
		display: inline-block;
		position: relative;
		min-width: 230px;
		min-height: 50px;
		padding: 13px 45px;
		border-radius: 100px;
		box-sizing: border-box;
		text-align: center;
		color:$color-white;
		background-color: rgba(0, 0, 0, 0.6);
		.toast-body {
			@include font-family($font-semibold);
			font-size:16px;
			line-height:24px;
		}
		.btn-close {
			position: absolute;
			top:50%;
			transform:translateY(-50%);
			right:26px;
			width:12px;
			height:12px;
			border:0;
			background:url($link-fold) no-repeat center; //LGCOMSPEED-11
			[dir="rtl"] & {
					left:26px;
					right:auto;
			}
			>span{
				display: inline-block;
				text-indent: -999999px;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}
	@include screen(custom, max, $max-sm) {
		.toast-content {
			min-width:216px;
			min-height:46px;
			.toast-body {
				font-size:14px;
				line-height:20px;
			}
		}
	}
}
// [Start] LGEGMC-4319
// 모달 팝업 내부 토스트 메세지
.modal-toast-msg {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	&.fade {
		z-index: -9999;
	}
	&.show {
		bottom: 20px;
		z-index: 10; //LGEGMC-4297
	}
	.toast-content {
		display: inline-block;
		position: relative;
		min-width: 230px;
		min-height: 50px;
		padding: 13px 45px;
		border-radius: 100px;
		box-sizing: border-box;
		text-align: center;
		color: $color-white;
		background-color: rgba(0, 0, 0, 0.6);
		.toast-body {
			@include font-family($font-semibold);
			font-size: 16px;
			line-height: 24px;
		}
		.btn-close {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 16px;
			width: 12px;
			height: 12px;
			border: 0;
			background:url($link-fold) no-repeat center; //LGCOMSPEED-11
			[dir="rtl"] & {
				left: 26px;
				right: auto;
			}
			>span{
				display: inline-block;
				text-indent: -999999px;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}
	@include screen(custom, max, $max-sm) {
		.toast-content {
			min-width: 216px;
			min-height: 46px;
			.toast-body {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}
// 토스트 팝업 기본형
.toast-popup {
	position: fixed;
	bottom: 0;
	left: 50%;
	z-index: 10010;
	min-width: 440px;
	text-align: center;
	background-color: $bg-white;
	border-radius: 20px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	transform: translate(-50%, 100%);
	transition: all 0.3s;
	@include screen(custom, max, $max-sm) {
		min-width: 272px;
	}
	p {
		margin: 0; // reset
	}
	.carmine {
		color: $color-carmine !important;
	}
	.toast-content{
		padding: 23px 40px 20px;
		@include screen(custom, max, $max-sm) {
			padding: 26px 24px 15px;
		}
	}
	&.show {
		bottom: 30px;
		z-index: 100000; // 챗봇 z-index 고려
		transform: translate(-50%, 0%);
	}
	.toast-header {
		.tit {
			display: block;
			font-size: 20px;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-sm) {
				font-size: 16px;
			}
		}
	}
	.toast-body {
		font-size: 16px;
		@include screen(custom, max, $max-sm) {
			font-size: 14px;
		}
		p {
			margin-bottom: 10px;
			@include screen(custom, max, $max-sm) {
				margin-bottom: 0;
			}
		}
	}
	.toast-footer {
		margin-top: 15px;
		@include screen(custom, max, $max-sm) {
			margin-top: 10px;
		}
		.btn {
			height: 45px;
			line-height: 1.5;
			@include screen(custom, max, $max-sm) {
				height: 40px;
				line-height: 1.14;
			}
			&:only-child {
				width: 100%;
			}
		}
	}
	.btn-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 44px;
		height: 44px;
		border: 0;
		border-radius: 20px;
		background: url('/lg5-common-gp/images/common/icons/gnb-close.svg') no-repeat center;
		cursor: pointer;
		[dir="rtl"] & {
			left: 0;
			right: auto;
		}
	}
	.toast-count {
		background: $bg-whitesmoke;
		left: 0;
		bottom: 0;
		border-radius: 0 0 20px 20px;
		padding: 8px 0;
		@include font-family($font-semibold);
		color: $color-nightrider;
		@include screen(custom, max, $max-sm) {
			font-size: 14px;
		}
	}
}
// [End] LGEGMC-4319

// LGEGMC-3710 start
.toast-signup {
	position: fixed;
	bottom: 30px;
	left: 50%;
	padding: 24px 30px;
	width: 430px;
	text-align: center;
	background-color: $bg-white;
	border-radius: 20px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	transform: translateX(-50%);
	@include screen(custom, max, $max-sm) {
		padding: 24px;
		width: 272px;
		bottom: 50%;
		transform: translate(-50%, 50%);
	}
	&.fade {
		z-index: -9999;
	}
	&.show {
		z-index: 100000;
	}
	.toast-header {
		.tit {
			display: block;
			padding-top: 36px;
			background: url('/lg5-common-gp/images/common/icons/icon-toast-signup.gif') no-repeat 49% 0;
			background-size: 36px 36px;
			font-size: 20px;
			@include font-family($font-semibold);
		}
	}
	.toast-body {
		margin-top: 10px;
		font-size: 16px;
		@include screen(custom, max, $max-sm) {
			font-size: 14px;
		}
		p {
			margin-bottom: 10px;
		}
	}
	.toast-footer {
		margin-top: 20px;
		.btn {
			width: 100%;
		}
	}
	.btn-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 44px;
		height: 44px;
		border: 0;
		border-radius: 20px;
		background: url('/lg5-common-gp/images/common/icons/gnb-close.svg') no-repeat center;
		cursor: pointer;
		[dir="rtl"] & {
			left: 0;
			right: auto;
		}
	}
}
// LGEGMC-3710 end

//toast-coupon start
.toast-coupon {
	position: fixed;
	bottom: 30px;
	left: 50%;
	width: 430px;
	text-align: center;
	background-color: $bg-white;
	border-radius: 20px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	transform: translate(-50%, 100%);
	transition: all 0.3s;
	@include screen(custom, max, $max-sm) {
		width: 272px;
		bottom: 30px;
	}
	.toast-content{
		padding: 23px 40px 0;
		@include screen(custom, max, $max-sm) {
			padding: 26px 24px 0;
		}
	}
	&.fade {
		z-index: -9999;
	}
	&.show {
		z-index: 100000; // 챗봇 z-index 고려
		transform: translate(-50%, 0%);
	}
	.toast-header {
		.img-box{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 350px;
			height: 90px;
			margin-bottom: 10px;
			@include screen(custom, max, $max-sm) {
				width: 100%;
				height: 58px;
				margin-bottom: 8px;
			}
			img{
				&.baseImg{
					max-height: 60px;
					@include screen(custom, max, $max-sm) {
						max-height: 50px;
					}
				}
				&.pc{
					max-height: 130px;
				}
				&.mobile{
					max-height: 120px;
				}
			}
			.out-act{
				display: none;
			}
			&.out-img{
				height: auto;
				padding: 60px 0 0;
				.baseImg{
					display: none !important;
				}
				.out-act{
					display: flex !important;
					position: absolute;
					top: -50px;
				}
				@include screen(custom, max, $max-sm) {
					padding: 40px 0 0;
				}
			}
		}
		.tit {
			display: block;
			font-size: 20px;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-sm) {
				font-size: 16px;
			}
			
		}
		.carmine {
			color:$color-carmine;
		}
	}
	.toast-body {
		//@at-root: 10px;
		font-size: 16px;
		@include screen(custom, max, $max-sm) {
			font-size: 14px;
		}
		p {
			margin-bottom: 10px;
			@include screen(custom, max, $max-sm) {
				margin-bottom: 0px;
			}
		}
	}
	.toast-footer {
		margin: 15px 0 20px;
		@include screen(custom, max, $max-sm) {
			margin: 10px 0 15px;
		}
		.btn {
			width: 100%;
			height: 45px;
			line-height: 21px;
			@include screen(custom, max, $max-sm) {
				height: 40px;
				line-height: 16px;
			}
		}
	}
	.btn-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 44px;
		height: 44px;
		border: 0;
		border-radius: 20px;
		background: url('/lg5-common-gp/images/common/icons/gnb-close.svg') no-repeat center;
		cursor: pointer;
		[dir="rtl"] & {
			left: 0;
			right: auto;
		}
	}
	.toast-count{
		background: $bg-whitesmoke;
		width: 100%;
		left: 0;
		bottom: 0;
		border-radius: 0 0 20px 20px;
		padding: 8px 0;
		color:$color-nightrider;
		@include font-family($font-semibold);
		.carmine {
			color:$color-carmine;
		}
		@include screen(custom, max, $max-sm) {
			font-size: 14px;
		}
	}
}
//toast-coupon end


body{
	&.m-menu-open{
		.toast{
			z-index:1 !important;
		}
	}
}