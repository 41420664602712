.modal-installration {
  display:none;
  &.active{
    display:block;
  }
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100%;
  overflow-y:scroll;
  z-index:10002;
  background-color:$color-white;
  text-align:left;
  [dir="rtl"] & {
    text-align:right;
  }
  .close {
    display:inline-block;
    position:fixed;
    right:50px;
    top:50px;
    width:24px;
    height:24px;
    padding-top:24px;
    overflow:hidden;
    background: url(/lg5-common-gp/images/common/icons/modal-close.svg) no-repeat 0 0;
    background-size: 100% auto;
    [dir="rtl"] & {
      right:auto;
      left:50px;
    }
    @include screen(custom, max, $max-sm){
      right:14px;
      top:14px;
      width:18px;
      height:18px;
      [dir="rtl"] & {
        right:auto;
        left:14px;
      }
    }
  }
  .layer-content {
    padding:146px 20px;
    max-width:1106px;
    margin:0 auto;
    @include screen(custom, max, $max-sm){
      padding:59px 24px 80px;
    }
  }
  h1 {
    @include font-family($font-semibold);
    font-size:28px;
    @include pxtoemLineHeight(28, 32);
    color:$color-black;
    padding-bottom:24px;
    border-bottom:3px solid $line-darkgray;
    @include screen(custom, max, $max-sm){
      font-size:26px;
      @include pxtoemLineHeight(26, 30);
      padding-bottom:17px;
    }

  }
  .layer-inner {
    padding-top:38px;
    @include screen(custom, max, $max-sm){
      padding-top:27px;
    }
    .common-box {
      padding-bottom:56px;
      @include screen(custom, max, $max-sm){
        padding-bottom:31px;
      }
      .common-tit {
        @include font-family($font-semibold);
        font-size:24px;
        @include pxtoemLineHeight(24, 28);
        color:$color-black;
        padding-bottom:30px;
        @include screen(custom, max, $max-sm){
          font-size:22px;
          @include pxtoemLineHeight(22, 28);
          padding-bottom:25px;
        }
      }
      .common-contents-wrap {
        display:flex;
        flex-wrap:wrap;
        width:calc(100% + 14px);
        margin:-7px;
        @include screen(custom, max, $max-sm){
          width:100%;
          margin:0;
        }
      }
      .common-content {
        flex:1;
        margin:0 7px;
        padding:24px;
        background-color:$bg-whitesmoke;
        @include screen(custom, max, $max-sm){
          flex:none;
          margin:0;
          padding:20px;
          width: 100%;
          & + .common-content {
            margin-top:14px;
          }
        }
        .top {
          @include font-family($font-semibold);
          font-size:18px;
          @include pxtoemLineHeight(18, 24);
          color:$color-nightrider;
          padding-bottom:14px;
          border-bottom:1px solid $line-graygoose;
          @include screen(custom, max, $max-sm){
            padding-bottom:15px;
          }
          img {
            width:48px;
            height:48px;
            margin-right:8px;
            @include screen(custom, max, $max-sm){
              width: 24px;
              height:24px;
              vertical-align:top;
            }
          }
        }
        .bottom {
          padding-top:17px;
          @include screen(custom, max, $max-sm){
            padding-top:16px;
          }
          p {
            @include font-family($font-semibold);
            font-size:16px;
            @include pxtoemLineHeight(16, 24);
            color:$color-nightrider;
            margin-bottom:0;
          }
          ul {
            padding-top:11px;
            li {
              position:relative;
              @include font-family($font-semibold);
              font-size:14px;
              @include pxtoemLineHeight(14, 20);
              color:$color-dimgray;
              padding-left:12px;
              margin-bottom:2px;
              [dir="rtl"] & {
                padding-left:0;
                padding-right:12px;
              }
              &:before {
                content:'';
                position:absolute;
                top:6px;
                left:0;
                width:4px;
                height:4px;
                background-color:$bg-dimgray;
                [dir="rtl"] & {
                  left:auto;
                  right:0;
                }
              }
            }
          }
        }
      }
    }
    .accodian-box {
      border-top:1px solid $line-gray;
      .item {
        &.on {
          button:before {
            transform: rotate(180deg);
          }
        }
        button {
          position:relative;
          width: 100%;
          @include font-family($font-semibold);
          font-size:20px;
          @include pxtoemLineHeight(20, 24);
          color:$color-nightrider;
          padding:18px 24px 21px;
          text-transform:none;
          border-bottom:1px solid $line-gray;
          text-align: left;
          [dir="rtl"] & {
            text-align:right;
          }
          @include screen(custom, max, $max-sm){
            @include pxtoemLineHeight(20, 26);
            padding:17px 10px 20px;
          }
          &:before {
            content:"";
            display:inline-block;
            float:right;
            width:24px;
            height:24px;
            background-image:url(/lg5-common-gp/images/common/icons/install-popup-arrow.svg);
            background-repeat:no-repeat;
            [dir="rtl"] & {
              float:left;
            }
            @include screen(custom, max, $max-sm){
              width:16px;
              height:16px;
              background-size:16px auto;
              margin-top:6px;
            }
          }
        }
        .accodian-content {
          padding:27px 24px 31px;
          display:none;
          overflow:hidden;
          border-bottom:1px solid $line-gray;
          @include screen(custom, max, $max-sm){
            padding:27px 10px 20px;
          }
        }
        .code {
          @include font-family($font-semibold);
          font-size:20px;
          @include pxtoemLineHeight(20, 24);
          color:$color-nightrider;
          padding-top:20px;
          padding-bottom:20px;
          &:first-child {
            padding-top:0;
          }
        }
        .service-name {
          @include font-family($font-semibold);
          font-size:18px;
          @include pxtoemLineHeight(18, 22);
          color:$color-nightrider;
          padding-bottom:15px;
        }
        ul {
          li {
            position:relative;
            @include font-family($font-semibold);
            font-size:16px;
            @include pxtoemLineHeight(16, 24);
            color:$color-nightrider;
            padding-left:14px;
            margin-bottom:14px;
            &:last-child {
              margin-bottom:0;
            }
            [dir="rtl"] & {
              padding-left:0;
              padding-right:14px;
            }
            &:before {
              content:'';
              position:absolute;
              top:10px;
              left:0;
              width:4px;
              height:4px;
              background-color:$bg-nightrider;
              [dir="rtl"] & {
                left:auto;
                right:0;
              }
              @include screen(custom, max, $max-sm){
                top:9px;
              }
            }
            .list-inner {
              padding-top:7px;
              margin-left:-4px;
              [dir="rtl"] & {
                margin-left:0;
                margin-right:-4px;
              }
              li {
                @include font-family($font-semibold);
                font-size:14px;
                @include pxtoemLineHeight(14, 20);
                color:$color-dimgray;
                padding-bottom:4px;
                padding-left:0;
                margin-bottom:0;
                &:before {
                  content:none;
                }
                &:last-child {
                  padding-bottom:0;
                } 
                [dir="rtl"] & {
                  padding-right:0;
                }
              }
            }
            .table-list {
              padding-top:15px;
              margin-left:-14px;
              [dir="rtl"] & {
                margin-left:0;
                margin-right:-14px;
              }
              table {
                border:1px solid $line-graygoose; 
                thead {
                  tr th:last-child {
                    border-right:0;
                  }
                  th {
                    @include font-family($font-semibold);
                    font-size:14px;
                    @include pxtoemLineHeight(14, 16);
                    color:$color-nightrider;
                    background-color:$bg-desertstorm;
                    padding:14px 0;
                    border-bottom:1px solid $line-graygoose;
                    border-right:1px solid $line-graygoose;
                  }
                }
                tbody {
                  tr:first-child td {
                    border-top:0;
                  }
                  tr td:last-child {
                    border-right:0;
                  }
                  td {
                    @include font-family($font-semibold);
                    font-size:14px;
                    @include pxtoemLineHeight(14, 16);
                    color:$color-nightrider;
                    padding:14px 0;
                    border-top:1px solid $line-graygoose;
                    border-right:1px solid $line-graygoose;
                  }
                }
              }
            }
            span {
              display:block;
              @include font-family($font-semibold);
              font-size:14px;
              @include pxtoemLineHeight(14, 20);
              color:$color-dimgray;
              padding-top:13px;
              margin-left:-14px;
              [dir="rtl"] & {
                margin-left:0;
                margin-right:-14px;
              }
            }
          }
        }
      }
    }
  }
}