// siblings
.model-group {
	position: relative;
	overflow:hidden;
	height: auto;
	min-height: 67px;
	@include screen(custom, max, $max-sm) {
		min-height: 56px;
	}
	@include screen(custom, max, 445) {
		min-height: 63px;
	}
	.inner {
		display:inline-block;
		margin:0 auto;
		overflow:hidden;
		@extend %clearfix;
		text-align:center;
		width:100%;
		> li {
			display:inline;
		}
		a {
			display:inline-block;;
			text-decoration:none;
			&.swatch {
				overflow: visible;
				position: relative;
				margin: 4px 5px;
				width: 20px !important;
				height: 20px !important;
				padding: 0;
				color: transparent;
				cursor: pointer;
				vertical-align: top;
				border-radius: 10px;
				border:1px solid #9c9c9c;
				box-sizing:border-box;
				&:before {
					content: '';
					position:absolute;
					left:-5px;
					top:-5px;
					width:28px;
					height:28px;
					border:1px solid transparent;
					box-sizing:border-box;
					border-radius:14px;
				}
				&.active {
					&:after {
					content: '';
					position:absolute;
					left:-5px;
					top:-5px;
					width:28px;
					height:28px;
					border:1px solid $line-carmine;
					box-sizing:border-box;
					border-radius:14px;
					}
				}
				&:nth-child(n+7) {display:none;}
				@include screen(custom, max, $max-sm) {
					width: 14px !important;
					height: 14px !important;
					margin:4px 2px;
					&:before,
					&.active:after {
						left:-4px;
						top:-4px;
						width: 20px;
						height: 20px;
						border-radius:50%;
					}
				}
			}
			&.size {
				$active-border-width:2px;
				position: relative;
				font-size:12px;
				line-height:1.7em;
				box-sizing:border-box;
				// 20200325 START 이상현 : size siblings 최소 너비 지정 및 텍스트 길이 대응
				// 20200319 START 박지영 : size siblings 넓이 유동적으로 수정
				width:auto;
				// 20200319 END
				min-width:30px + ($active-border-width * 2);
				height:22px;
				color:$color-dimgray;
				text-align:center;
				vertical-align:top;
				@include font-family($font-semibold);
				border:1px solid #d9d9d9;
				border-radius:3px;
				margin:2px 1px 4px;
				// 20200319 START 박지영 : size siblings 넓이 유동적으로 수정
				padding:0 ($active-border-width * 2);
				// 20200319 END
				// 20200325 END
				&.active {
					color:$color-nightrider;
					&:after {
						content:"";
						position: absolute;
						top:-$active-border-width; 
						left:-$active-border-width;
						right:-$active-border-width;
						height:22px + $active-border-width;
						border-radius:3px;
						border:$active-border-width solid #000;
					}
					&:before {
						content:"";
						position: absolute;
						display:block;
					}
				}
				&:focus{
					outline:2px solid $line-carmine;
					outline-offset:-4px;
				}
				//&:nth-child(n+7) {display:none;}
				@include screen(custom, max, $max-sm) {
					//&:nth-child(6) {display:none;}
					font-size:11px;
					line-height: 1.8em;
					// 20200325 START 이상현 : size siblings 최소 너비 지정 및 텍스트 길이 대응
					// 20200319 START 박지영 : size siblings 넓이 유동적으로 수정
					width:auto;
					min-width:24px + $active-border-width;
					height:20px;
					padding:0 $active-border-width;
					&.active:after {
						height:20px + $active-border-width;
					}
					// 20200319 END
					// 20200325 END
				}
				&:focus {
					&.active {
						&:before {
							top:1px;
							// 20200325 START 이상현 : size siblings 최소 너비 지정 및 텍스트 길이 대응
							left:$active-border-width/2;
							right:$active-border-width/2;
							// 20200319 START 박지영 : size siblings 넓이 유동적으로 수정
							width:calc(100% - #{$active-border-width});
							// 20200319 END
							// 20200325 END
							height:20px;
							outline: -webkit-focus-ring-color auto 1px;
							outline:focus-ring-color auto 1px;
						}
					}
				}
			}
		}
		
	}

	// PJTQUICKWIN 20220225 - GPC0007.scss에서 가져옴
	&.limited{
		.inner {
			display: flex;
			flex-wrap:wrap;
			justify-content: left;
			position: absolute;
			left: 0;
			right: 0;
			top:-7px;
			background: #fff;
			z-index: 1;
			padding: 7px 53px 7px 14px;
			border-radius: 5px;
			box-shadow: 0px 0px 3px 0px rgba(0,0,0,.3);
			@include screen(custom, max, $max-md) {
				padding: 7px 35px 7px 5px;
			}
			[dir="rtl"] & {
				padding: 7px 14px 7px 53px;
				@include screen(custom, max, $max-md) {
					padding: 7px 5px 7px 35px;
				}
			}
			a{
				display:block;
				&.swatch{
					margin:4px 6px;
					@include screen(custom, max, $max-md) {
						margin:4px 5px;
					}
				}
				&.size{
					margin:2px 3px 4px;
				}
			}
		}
		.btn-limited{
			position: absolute;
			right: 13px;
			top:1px;
			z-index: 3;
			border: 0;
			font-size:14px;
			background: url($accordion-open-nightrider) no-repeat left center; //LGCOMSPEED-6(8th)
			background-size: 14px auto !important;
			padding: 0 0 0 14px;
			height: 24px;
			cursor: default;
			&:focus{outline:0;}
			@include screen(custom, max, $max-md) {
				background-size: 10px auto !important;
				padding: 0 0 0 11px;
				height: 18px;
				font-size: 12px;
				right: 7px;
				top:2px;
				outline:inherit;
			}
			[dir="rtl"] & {
				left: 13px;
				right:inherit;
				background: url($accordion-open-nightrider) no-repeat right center; //LGCOMSPEED-6(8th)
				padding: 0 14px 0 0;
				@include screen(custom, max, $max-md) {
					padding: 0 10px 0 0;
					left: 5px;
				}
			}
		}
	}
	&:hover{
		@include screen(custom, min, $min-sm) {
			overflow: visible;
		}
	}
	&.open{
		overflow: visible;
	}
	// PJTQUICKWIN 20220225  - GPC0007.scss에서 가져옴
}

//PRJOBSEMI-1 add && LGEAU-394 add
.price-vip-Installment{
	width:100%;
	margin:2px auto;
	.price-vip,.price-limited,.price-b2b{
		@include font-family($font-semibold);
		color: $color-carmine;
		text-align: center;
		line-height: 1.2em;
		font-size: 14px;
		margin: 0 auto;
		overflow:hidden;
		white-space: nowrap;
		text-overflow:ellipsis;
	}
	// LGEGMC-3282 : price-zippay add
	.price-installment,.price-afterpay,.price-zippay{
		@include font-family($font-semibold);
		text-decoration: underline;
		color: $color-black;
		text-align: center;
		line-height: 1.4em;
		max-height: 2.8em;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 14px; // LGEGMC-3282
		margin: 0 auto;
		.afterImg{
			position: relative;
			display: inline-block;
			vertical-align: top;
			margin-left:4px;
			img{
				width:65px;
				height:14px;
			}
		}
		.zippayImg{
			position: relative;
			display: inline-block;
			vertical-align: top;
			margin-left:4px;
			img{
				width:32px;
				height:12px;
			}
		}
		//LGETW-729 Start
		&.no-link {
			pointer-events: none;
			text-decoration: none;
		}
		//LGETW-729 End
	}
	// [Start] LGEUK-1089
	[data-countrycode="uk"] & {
		.price-vip {
			font-size: 17px;
		}
	}
	// [End] LGEUK-1089
	@include screen(custom, max, $max-sm) {
		.price-vip,.price-limited{
			font-size:12px;
		}
		// [Start] LGEUK-1089
		[data-countrycode="uk"] & {
			.price-vip {
				font-size: 15px;
			}
		}
		// [End] LGEUK-1089
		// LGEGMC-3282 : price-zippay add
		.price-installment,.price-afterpay,.price-zippay{
			font-size:13px;
			margin:2px auto;
			line-height:1.2em;
			.afterImg{
				margin-left:2px;
				img{
					width:47px;
					height:10px;
				}
			}
			.zippayImg{
				margin-left:2px;
				img{
					width:21px;
					height:8px;
				}
			}
		}	
	}
	// LGEIS-598 start
	.price-installment-text{
		@include font-family($font-semibold);
		color: $color-black;
		text-align: center;
		line-height: 1.4em;
		max-height: 2.8em;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 16px;
		margin: 0 auto;
	}
	@include screen(custom, max, $max-sm) {
		.price-installment-text{
			font-size:13px;
			margin:2px auto;
			line-height:1.2em;
		}
	}
	// LGEIS-598 end
}

//PJTMB-UI 210603 
.member-text,.cheaper-text,.regular-price-text {
	width:100%;
	margin:0 auto;
	p{
		margin:0;
		padding:0 0;
		text-align:center;
		font-size:14px;
		@include font-family($font-regular);
		line-height:1.5em;
		@include screen(custom, max, $max-sm) {
			font-size:12px;
			line-height:1.3em;
		}
	}
	span{
		display:inline-block;
		color:$color-carmine;
		font-weight: 600;
	}
}
// LGCOMPA-231 start
.member-link-text {
	position: relative;
	display: inline-block;
	color: $color-nightrider;
	line-height: 1.25;
	&:focus, &:hover {
		color: $color-black;
	}
	&.ico-blank {
		text-decoration: underline;
		&:after {
			@extend %linkicon;
			background: url('/lg5-common-gp/images/common/icons/blank-dimgray.svg') no-repeat 0 0;
			margin-left: 6px;
			margin-top: -2px;
			vertical-align: middle;
		}
		[dir="rtl"] & {
			&:after {
				margin-left: 0;
				margin-right: 6px;
				transform: rotateY(180deg);
				-moz-transform: rotateY(180deg);
				-ms-transform: rotateY(180deg);
				-o-transform: rotateY(180deg);
			}
		}
	}
}
// LGCOMPA-231 end

// [Start] LGCOMEG-235
.coupon-price {
	width: 100%; // for 'gpc0007'
	p {
		margin: 0;
		@include font-family($font-semibold);
		font-size: 13px;
		color: $color-nightrider;
		text-align: center;
		@include screen(custom, max, $max-xs) {
			font-size: 12px;
		}
		em {
			display: inline-block;
			color: $color-carmine;
			font-style: normal;
		}
	}
}
// [End] LGCOMEG-235

// common price
.price-area.total {
	min-height:32px;
	margin:auto;
	text-align:center;
	max-width:95%;
	width:100%;
	@include screen(custom, max, $max-sm) {
		max-width:100%;
	}
	// 20200601 END
	/* PJTOBS-32 Start */
	&.vip-price-area {
		padding-top: 20px;
		min-height: 52px;
		&.type-promotion {
			.purchase-price {
				.price-b2b, // [LGEMS-444] b2b price 추가
				.vip-price {
					text-align: left;
					[dir="rtl"] & {text-align: right;}
				}
			}
		}
		@include screen(custom, max, $max-md) {
			min-height: 70px;
		}
		@include screen(custom, max, $max-sm) {
			.purchase-price {
				line-height: 24px;
			}
			&.type-promotion {
				.purchase-price {
					.price-b2b, // [LGEMS-444] b2b price 추가
					.vip-price {
						text-align: center;
						[dir="rtl"] & {text-align: center;}
					}
				}
			}
		}
	}
	/* PJTOBS-32 End */
	.msrp,
	.purchase-price,
	.product-price{
		vertical-align:middle;
	}
	.msrp {
		@include font-family($font-semibold);
		font-size:14px;
		line-height:32px;
		color:$color-nightrider;
		text-transform:uppercase;
		padding-right:4px;
		@include screen(custom, max, $max-md) {
			line-height:24px;
		}
	}
	.purchase-price {
		//height:32px;
		font-size:24px;
		line-height:32px;
		color:$color-black;
		@include screen(custom, max, $max-sm) {
			font-size:16px;
			@include font-family($font-semibold);
		}
		.price {padding-right:4px;}
		/* PJTOBS-32 Start */
		.price-b2b, // [LGEMS-444] b2b price 추가
		.vip-price {
			display: block;
			height: 20px;
			font-size: 14px;
			font-weight: 700;
			line-height: 20px;
			color: $color-carmine;
			text-transform: uppercase;
		}
		/* PJTOBS-32 End */
	}
	.product-price {
		@include screen(custom, min, $min-md) {
			/* PJTOBS-32 Start */
			min-height: auto;
			/* PJTOBS-32 End */
		}
		@include screen(custom, max, $max-sm) {
			margin-top:-7px;
		}
		.price,
		.legal {
			// WA-Common-Price : price 마크업 div → del 수정
			display: block;
			font-size:14px;
			line-height:16px;
			@include font-family($font-semibold);
			@include screen(custom, min, $min-md) {
				min-height:16px;
				text-align:left;
				[dir="rtl"] & {text-align:right;}
			}
			@include screen(custom, max, $max-sm) {
				display:inline-block;
				[data-countrycode=it] &{
					display:block !important;
					text-align:center !important;
					br{
						display:none !important;
					}
				}
			}
			&.and-txt{
				@include screen(custom, max, $max-sm) {
					display:block;
					text-align:center;
					.legal-txt{display:inline-block;}
					.legal-price{display:inline-block;}
				}
			}
		}
		.price {
			color:$color-dimgray;
			text-decoration: none;
			span:not(.uvp) {
				text-decoration: line-through;
			}
			// [Start] LGEDE-1151
			&.has-uvp{
				font-size: 0;
				@include screen(custom, max, $max-sm) {
					font-size: 14px;
				}
				.uvp{
					margin-right: 3px;
					vertical-align: bottom;
					font-size: 14px;
					@include screen(custom, max, $max-md) {
						vertical-align:inherit;
					}
					& ~ span{
						text-decoration: line-through;
						vertical-align: bottom;
						font-size: 14px;
						@include screen(custom, max, $max-md) {
							vertical-align:inherit;
						}
					}
				}
				.number{
					@include screen(custom, max, $max-md) {
						margin-right: 3px;
					}
					@include screen(custom, max, $max-sm) {
						margin-right: 0px;
					}
				}
			}
			// [End] LGEDE-1151
			// LGCOMPL-215 start
			.lowest-price-mark {
				margin-left: 2px;
				font-size: 14px;
				vertical-align: middle;
			}
			// LGCOMPL-215 end
		}
		.legal {color:$color-carmine;}
	}
	.text {
		height:32px;
		font-size:16px;
		color:$color-black;
		@include font-family($font-semibold);
		@include screen(custom, max, $max-md) {
			height:auto;
			min-height:51px;
		}
		@include screen(custom, max, $max-sm) {
			font-size:14px;
			line-height:18px;
		}
	}
	.msrp,
	.purchase-price,
	.product-price,
	.text {
		display:none;
	}
	&.type-default {
		.purchase-price {
			display:inline-block;
		}
		.msrp,
		.product-price,
		.text {
			display:none;
		}
	}
	&.type-promotion {
		.product-price,
		.purchase-price {
			display:inline-block;
			@include screen(custom, max, $max-sm) {
				width:100%;
			}
		}
		.msrp,
		.text {
			display:none;
		}
	}
	&.type-text {
		.msrp,
		.purchase-price,
		.product-price {
			display:none;
		}
		.text {
			display:inline-block;
		}
	}
	&.type-msrp {
		.msrp,
		.purchase-price {
			display:inline-block;
		}
		.product-price,
		.text {
			display:none;
		}
	}
	&.type-none {
		.msrp,
		.purchase-price,
		.product-price,
		.text {
			display:none;
		}
	}
}

//sold-out
.sold-out{
	text-align: center;
	width: 100%;
	margin: 5px auto;
	line-height:1em;
	.icon {
		display: inline-block;
		margin-right: 3px;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		background:url('/lg5-common-gp/images/common/icons/stock-out.svg') no-repeat 0 0;
		background-size: 20px;
		line-height: 20px;
		[dir="rtl"] & {margin-right:0;margin-left:3px;}
	}
	.text {
		display: inline-block;
		@include font-family($font-regular);
		font-size: 20px;
		line-height: 1em;
		vertical-align: middle;
		color: $color-carmine;
		@include screen(custom, max, $max-sm) {
			font-size: 16px;
		}
	}
}

// LGECZ-402 s
// 기본과 scale-14,16 으로 구분 폰트 설정이 필요하면 해당 컴포넌트 scss에서 수정
.lowest-price{
	width: 100%;
	p{
		margin: 2px auto 0;
		font-size: 13px;
		color: $color-nightrider;
		line-height: 1.4em;
		@include font-family($font-semibold);
		.price{
			display: inline-block;
			color: $color-carmine;
		}
		@include screen(custom, max, $max-sm) {
			font-size:12px;
		}
		@include screen(custom, max, 320) {
			font-size:11px;
			line-height:1.2em;
		}
		// LGCOMPL-215 start
		[data-countrycode="pl"] & {
			color: $color-carmine;
		}
		// LGCOMPL-215 end
	}
	&.scale-14{
		p{
			font-size: 14px;
			@include screen(custom, max, $max-sm) {
				font-size:14px;
			}
			@include screen(custom, max, 320) {
				font-size:13px;
			}
		}
	}
	&.scale-16{
		p{
			font-size: 16px;
			@include screen(custom, max, $max-sm) {
				font-size:16px;
			}
			@include screen(custom, max, 320) {
				font-size:14px;
			}
		}
	}
}
// LGECZ-402 e
// LGCOMPL-215 start
.lowest-price-mark {
	font-size: inherit;
	@include font-family($font-regular);
	[data-countrycode="pl"] & {
		color: $color-carmine;
	}
}
// LGCOMPL-215 end

// campaign
.btn-campaign-box{
	width:100%;
	margin:12px auto;
	height:58px;
	@include screen(custom, max, $max-sm) {
		height:50px;
		margin:12px auto 10px;
	}
	&.hidden{
		.btn-campaign{
			display:none !important;
		}
	}
	.btn-campaign{
		width:100%;
		position: relative;
		height:58px;
		display:block;
		overflow: hidden;
		@include screen(custom, max, $max-sm) {
			height:50px;
		}
		&:hover{
			text-decoration:none;
			box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.08);
			@include screen(custom, max, $max-sm) {
				box-shadow:inherit !important;
			}
		}
		&:hover .campaign-banner,&:focus .campaign-banner{
			opacity:1;
			z-index:3;
			@include screen(custom, max, $max-sm) {
				display:none !important;
				opacity:0;
				z-index:-1;
			}
		}
		.text-bold {
			font-weight:700 !important;
		}
		.text-italic{
			font-style:italic !important;
			padding: 0 3px !important;
		}
		.text-all{
			font-weight:700 !important;
			font-style:italic !important;
			padding: 0 3px !important;
		}
		.title-campaign{
			position: absolute;
			left:0;
			right:0;
			top:0;
			bottom:0;
			display:flex;
			justify-content:center;
			align-items:center;
			flex-flow:column;
			padding:8px 15px;
			border:1px solid $line-gray;
			border-radius:3px;
			background:$bg-whitesmoke;
			&:hover{
				text-decoration:none;
			}
			.campaign-txt{
				width: 100%;
				color:#111111;
				font-size:15px;
				text-align:center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-decoration: none;
				line-height:24px;
				@include screen(custom, max, $max-sm) {
					font-size:12px;
					line-height:16px;
				}
			}
			.campaign-day{
				width: 100%;
				color:#111111;
				font-size:12px;
				text-align:center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-decoration: none;
				line-height:18px;
				font-weight: 700;
				@include screen(custom, max, $max-sm) {
					font-size:10px;
					line-height:16px;
				}
			}
		}
		.campaign-banner{
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			border:1px solid $line-gray;
			border-radius:3px;
			text-align:left;
			background:$bg-white;
			padding:0 15px;
			opacity:0;
			z-index:-1;
			display:flex;
			justify-content:center;
			align-items:center;
			-webkit-transition: all 0.1s ease;
			-moz-transition: all 0.1s ease;
			-o-transition: all 0.1s ease;
			transition: all 0.1s ease;
			&.align-left{
				justify-content: flex-start;
			}
			&.align-center{
				justify-content: center;
			}
			&.align-right{
				justify-content: flex-end;
			}
			.campaign-info{ 
				font-size:13px;
				color:$color-nightrider;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				line-height: 1.5em;
				max-height: 3em;
				padding:0 0;
				margin:0 0;
			}
		}
	}
}
// LGETR-235 start
.recommended-retail-price {
	margin: 2px 0;
	width: 100%;
	font-size: 14px;
	color: $color-nightrider;
	@include screen(custom, max, $max-sm) {
		@include font-family($font-semibold);
		font-size: 12px;
	}
}
// LGETR-235 end
// LGEPL-546 start
a:hover {
	.member-link {
		text-decoration: underline;
	}
}
// LGEPL-546 end

// PJTQUICKWIN 20220224 박지영 START - 목록 디자인 변경
.cardlist-box {
	.model-group {
		width: 100%;
		height: 24px;
		min-height: 24px !important;
		max-width: 100% !important;
		overflow: hidden;
		&.type-color {
			max-width: 192px !important;
			@include screen(custom, max, $max-sm) {
				max-width: 104px !important;
			}
			.inner {
				max-width: 192px !important;
			}
			@include screen(custom, max, $max-sm) {
				max-width: 104px !important;
			}
			&.limited {
				.inner {
					min-width: 192px !important;
				}
				@include screen(custom, max, $max-sm) {
					.inner {
						min-width: 104px !important;
					}
				}
				.btn-limited {
					left: calc(50% + 44px);
					[dir="rtl"] & {
						left: auto;					
						right: calc(50% + 44px);
					}
					@include screen(custom, max, $max-sm) {
						left: auto;
						right: 6px;
						[dir="rtl"] & {
							left: 6px;
							right: auto;
						}
					}
				}
			}
		}
		&.type-size {
			max-width: 220px !important;
			@include screen(custom, max, $max-sm) {
				max-width: 126px !important;
			}
			.inner {
				max-width: 220px !important;
			}
			@include screen(custom, max, $max-sm) {
				max-width: 126px !important;
			}
			&.limited {
				.inner {
					min-width: 220px !important;
				}
				@include screen(custom, max, $max-sm) {
					.inner {
						min-width: 126px !important;
					}
				}
				.btn-limited {
					left: calc(50% + 58px);
					[dir="rtl"] & {
						left: auto;					
						right: calc(50% + 58px);
					}
					@include screen(custom, max, $max-sm) {
						left: auto;
						right: 0;
						[dir="rtl"] & {
							left: 0;
							right: auto;
						}
					}
				}
			}
		}
		.inner {
			display: flex;
			flex-wrap:wrap;
			justify-content: center;
			a.swatch {
				width: 18px !important;
				height: 18px !important;
				border-color: rgba(0, 0, 0, 0.1);
				margin: 3px 6px;
				@include screen(custom, max, $max-sm) {
					margin: 3px 4px;
				}
				&:before {
					left: -4px;
					top: -4px;
					width: 24px;
					height: 24px;
					border-color: rgba(0, 0, 0, 0.19);
					border-radius: 50%;
				}
				&.active {
					&:before {
						border-color: $line-nero;
					}
					&:after {
						display: none;
					}
				}
			}
			a.size {
				min-width: 32px;
				border: 1px solid $line-graygoose;
				border-radius: 12px;
				font-size: 10px;
				color: $color-gray;
				height: 24px;
				line-height: 24px; // === height
				margin: 0 2px 0 3px;
				padding: 0 7px;
				@include screen(custom, max, $max-sm) {
					margin: 0 1px;
				}
				&.active {
					color: $color-nero;
					border-color: $line-darkgray;
					&:after {
						display: none;
					}
				}
			}
		}
		.btn-limited {
			display: none;
		}
		&.limited {
			.inner {
				top: -20px;
				left: 50%;
				transform: translateX(-50%);
				padding: 17px 54px 17px 17px;
				outline: 1px solid $line-graygoose !important;
				border-radius: 0;
				justify-content: left;
				[dir="rtl"] & {
					left: auto;
					right: 50%;
					transform: translateX(50%);
					padding: 17px 17px 17px 54px;
					justify-content: right;
				}
				@include screen(custom, max, $max-sm) {
					padding: 10px 35px 10px 12px;
					top: auto;
					bottom: -13px;
					[dir="rtl"] & {
						padding: 10px 12px 10px 35px;
					}
					a.swatch,
					a.size {
						&.under2 {
							display: none;
						}
					}
				}
				a.swatch {
					margin: 6px;
					@include screen(custom, max, $max-sm) {
						margin: 5px 4px;
					}
				}
				a.size {
					margin: 3px 2px 3px 3px;
					@include screen(custom, max, $max-sm) {
						margin: 2px 1px 3px;
					}
				}
			}
			&.type-size {
				[dir="rtl"] & {
				}
			}
			&.type-color {
				[dir="rtl"] & {
				}
			}
			&.open {
				overflow: visible !important;
				@include screen(custom, max, $max-sm) {
					&.type-size {
						.inner {
							&:after {
								// btn-limited 의 공간 확보
								width: 32px;
								height: 24px;
								margin: 2px 1px 3px;
							}
						}
					}
					&.type-color {
						.inner {
							&:after {
								// btn-limited 의 공간 확보
								width: 32px;
								height: 24px;
								margin: 2px 1px;
							}
						}
					}
					.inner {
						padding: 10px 12px 11px;
						a.size,
						a.swatch {
							&.under2 {
								display: block;
							}
						}
					}
				}
				.btn-limited {
					background: $bg-whitesmoke !important;
				}
			}
			&:hover {
				@include screen(custom, min, $min-md) {
					overflow: visible !important;
					.btn-limited {
						background: $bg-whitesmoke !important;
					}
				}
			}
			.btn-limited {
				display: block;
				font-size: 10px !important;
				color: $color-gray;
				border: 1px solid $line-graygoose !important;
				width: 32px;
				height: 24px !important;
				line-height: 22px; // === height
				padding: 0 !important;
				margin: 0;
				background: $bg-white !important;
				border-radius: 12px;
				top: 0 !important;
				left: 0;
				right: auto;
				@include screen(custom, max, $max-sm) {
				}
			}
		}
	}
	.price-vip-Installment {
		margin: 0;
		* {
			text-align: left !important;
		}
		// LGEGMC-3282 start : price-zippay add
		.price-installment,
		.price-afterpay,
		.price-zippay {
			font-size: 12px;
			@include pxtoemLineHeight(12, 14);
			.afterImg {
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					/* IE10-specific styles go here */
					vertical-align: auto;
				 }
			}
			.zippayImg {
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					/* IE10-specific styles go here */
					vertical-align: auto;
				 }
			}
		}
	 	.price-limited,
		.price-vip {
			font-size: 13px;
			@include pxtoemLineHeight(13, 15);
		}
		// 간격
		.price-vip + .price-installment {
			margin-top: 8px;
		}
	}
	.promotion-text {
		text-align: left;
		font-size: 13px;
		@include pxtoemLineHeight(13, 15);
		color:$color-nightrider;
		padding: 0;
		margin: 0;
		width: 100%;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		@include font-family($font-bold);
	}
	.member-text,
	.cheaper-text {
		p {
			font-size: 13px;
			@include pxtoemLineHeight(13, 15);
		}
	}
	.price-area.total {
		text-align: left;
		margin: 0 !important;
		max-width: 100%;
		min-height: 0;
		.purchase-price {
			width: auto;
			font-size: 22px;
			line-height: 1;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-sm) {
				margin-top: 3px !important;
				font-size: 22px !important;
				line-height: 1 !important;
			}
		}
		.product-price {
			width: auto;
			font-size: 12px;
			line-height: 1;
			@include screen(custom, max, $max-sm) {
				margin-top: 3px !important;
			}
			* {
				display: inline;
				font-size: 12px;
				@include pxtoemLineHeight(12, 14);
			}
		}
	}
	
	.out-of-stock,
	.sold-out {
		text-align: center;
		width: 100%;
		margin: 5px auto;
		line-height:1em;
		.icon {
			display: inline-block;
			margin-right: 3px;
			vertical-align: middle;
			width: 16px;
			height: 16px;
			background:url('/lg5-common-gp/images/common/icons/stock-out.svg') no-repeat 0 0;
			background-size: 16px;
			line-height: 16px;
			[dir="rtl"] & {margin-right:0;margin-left:3px;}
		}
		.text {
			display: inline-block;
			@include font-family($font-semibold);
			font-size: 14px;
			@include pxtoemLineHeight(14, 16);
			vertical-align: middle;
			color: $color-carmine;
			@include screen(custom, max, $max-sm) {
				font-size: 16px;
			}
		}
	}
	.recommended-retail-price {
		margin: 0;
		font-size: 13px;
		@include pxtoemLineHeight(13, 15);
		@include screen(custom, max, $max-sm) {
			font-size: 12px;
			@include pxtoemLineHeight(12, 14);
		}
	}
	// 간격
	.price-area.total + .promotion-text,
	.recommended-retail-price + .price-area.total,
	.price-vip-Installment + .price-area.total,
	.price-area.total + .member-text {
		margin-top: 8px;
	}
}
// PJTQUICKWIN 20220224 박지영 END - 목록 디자인 변경