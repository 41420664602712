.band-scroll {
	position:relative;
	overflow: hidden;
}
.the-search-window {
	// compoent set
	$wrap-width:860px;
	// 20200309 START 이상현 - 스크롤 바 제거
	// $scroll-position-adjust:8px;	// secondary view divide line position-y 
	// 20200309 END 이상현
	@mixin shorten{
		white-space:nowrap;
		text-overflow:ellipsis;
		overflow:hidden;
	}
	@mixin flex($direction:row) {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display:flex;
		-webkit-flex-direction: $direction;
		-ms-flex-direction: $direction;
		flex-direction: $direction;
		-webkit-box-lines: single; 
		-ms-flex-wrap: wrap;
		flex-wrap:wrap;			
	}
	// reset
	button {padding:0;border:none; background:none;}
	.link-text {
		&.color-nightrider {color:$color-nightrider;}
	}

	// 20200429 START 이상현 - gnb search rtl 화면 오류 수정
	display:none;
	padding: 0 0;
    height: 100vh;
    overflow: auto;
    position: absolute;
    z-index: 100;
    top: 0;
    left: -15px;
    right: -15px;
    width: auto;
	background-color:hsla(0, 100%, 100%, 1); // 2022.02.18 수정
	// 20200429 END
	// active
	&.active {
		display:block;
	}
	// top margin
	&:before {
		content:"";
		display:block;
		width:100%;
		// 2022.02.18 search input 수정 start
		@include screen(custom, min, $min-md){
			height:100px;
			@media screen and (max-height:768px){
				height:100/(768*0.01) * 1vh;
			}
		}
		@include screen(custom, min, $min-lg){
			height:180px;
			@media screen and (max-height:768px){
				height:180/(768*0.01) * 1vh;
			}
		}
		@include screen(custom, max, $max-sm){
			height:50px;
			content:none;
		}
		// 2022.02.18 search input 수정 end
	}
	// non active button
	.btn-close-cover {
		position: absolute;
		top:50px; 
		right:50px;
		[dir="rtl"] & {
			right:auto;
			left:50px;
		}
		@media screen and (max-height:768px){
			top:56px;
			top:6.51vh;
		}
		// 2022.02.18 search input 수정 start
		@include screen(custom, max, $max-sm){
			display: none;
		}
		// 2022.02.18 search input 수정 end
		.btn-close-search-window {
			vertical-align:top;
			width:20px;
			height:20px;
			background : {
				image:url(/lg5-common-gp/images/common/icons/modal-close.svg);
				repeat: no-repeat;
				position:0 0;
				size:100% auto;
			}
			cursor:pointer;
			@include screen(custom, max, $max-sm){
			vertical-align:baseline;
				width:auto;
				height:auto;
				background: none;
				span {
					font-size: 14px;
					line-height: 1em;
					color: $color-dimgray;
					@include font-family($font-semibold);
				}
			}
		}
	}
	// 2022.02.18 search input 수정 start
	.search-window-wrap {
		max-width:640px;
		margin:0 auto;
		padding-bottom: 100px;
		@include screen(custom, max, $max-sm){
			max-width:none;
			padding:0 0;
			margin:0 0;
		}
	}
	.title {
		text-align:center;
		font-size:48px;
		@include pxtoemLineHeight(48, 56);
		color:$color-black;
		@include font-family($font-semibold);
		margin:-9px 0 38px;
		@include screen(custom, max, $max-sm){
			display:none;
		}
	}
	// 2022.02.18 search input 수정 end
	// primary view
	.keyword-field {
		display:none;
		@extend %clearfix;
		&.active {
			display:block;
		}
		@include screen(custom, max, $max-sm){
			// padding:0 20px; // 2022.02.18 search input 수정
			padding:0 16px; 
		}
	}
	.input-keyword-field {
		// variable
		$search-button-size:24px;
		button {
			position: absolute;
			width:$search-button-size; 
			height:$search-button-size;
			background : {
				position:50% 50%;
				repeat:no-repeat;
			}
			cursor: pointer;
		}
		position:relative; 
		z-index:10;
		width:100%;
		text-align:center;
		.input-field {
			position: relative;
			height:58px;
			background-color:$bg-white;
			@include screen(custom, max, $max-sm){
				height:52px; // 2022.02.18 search input 수정
			}
			// 2022.02.18 search input 수정 start
			input[type="text"] {
				width:100%;
				height:100%;
				background-color:transparent;
				padding:0 79px;
				border:2px solid $line-darkgray;
				vertical-align: top;
				text-align:center;
				font-size:24px;
				color:$color-nightrider;
				border-radius: 29px;
				caret-color:$color-carmine;
				@include pxtoemLineHeight(24, 24);
				@include font-family($font-semibold);
				@include placeholder($bg-nobel);
				&:focus {
					outline:none;
					@include placeholder(transparent);
				}
				@include screen(custom, max, $max-sm){
					font-size:16px;
					@include pxtoemLineHeight(16, 20);
					color:$color-black;
					text-align:left;
					border-radius:0;
					border:none;
					padding:0 130px 0 56px;
					@include placeholder($color-dimgray);
					[dir="rtl"] & {
						text-align:right;
						padding: 0 56px 0 30px !important;
					}
				}
			}
			.btn-clear-input {
				display:none;
				width:16px;
				height:16px;
				font-size: 10px; // for ios
				background-image:url(/lg5-common-gp/images/common/icons/gnb-search-clear-update.svg);
				bottom:50%; 
				right:21px;
				transform:translateY(50%); 
				[dir="rtl"] & {
					right:auto;
					left:21px;
				}
				@include screen(custom, max, $max-sm){
					right:0;
					[dir="rtl"] & {
						left:0;
					}
				}
			}
			// 2022.02.18 search input 수정 end
			// event
			&.is-typing{
				.btn-clear-input {
					display:block;
				}				
			}
			.btn-close-mo{
				display:none;
			}
			.inner-mo-box{
				height: 100%;
				width: 100%;
			}
			@include screen(custom, max, $max-sm){
				display: flex;
				position: relative;
				justify-content: space-between;
				border-bottom: 1px solid #e4e4e4;
				.inner-mo-box{
					position: relative;
					input[type="text"]{
						padding: 0 30px 0 56px;
						width: 100%;
					}
				}
				.btn-close-mo{
					display: inline-flex;
					justify-content: right;
					align-items: center;
					padding:0 16px;
					max-width: 40%;
					.btn-close-search-window{
						position: relative;
						width: 100%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						text-align: right;
					}
				}
			}

			
		}
		// 2022.02.18 search input 수정 start
		button[type="submit"] {
			background-image:url(/lg5-common-gp/images/common/icons/gnb-search-black.svg);
			background-size:100% auto;
			top:17px;
			left:24px;
			[dir="rtl"] & {
				right:24px;
				left:auto; 
			}
			@include screen(custom, max, $max-sm){
				top:14px;
				left:16px;
				[dir="rtl"] & {right:16px;}
			}
		}
		// 2022.02.18 search input 수정 end
		.autoName-area{
			display:none;
			&.active{
				display:block;
			}
			// 2022.02.18 수정  start
			.autoName-box{
				&.gang-success{
					.autoName-list{
						display:none;
					}
					.success-seacrh{
						display:block;
					}
				}
				.autoName-list{	
					max-width: 494px;
					margin:0 auto;
					padding-top:28px;
					@include screen(custom, max, $max-sm){
						max-width:none;
						padding:0 16px 0 16px;
					}				
					li {
						border-bottom:1px solid $line-gray;
						padding:22px 0 18px;
						@include screen(custom, max, $max-sm){
							padding:20px 0;
						}
						a {
							position:relative;
							display: block;
							display: -webkit-box;
							-webkit-line-clamp: 1; 
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
							font-size:16px;
							color:$color-nightrider;
							@include pxtoemLineHeight(16, 20);
							@include font-family($font-semibold);
							text-align:left;
							padding-right:28px; 
							[dir="rtl"] & {
								text-align:right;
								padding-right:0;
								padding-left:28px;
							}
							@include screen(custom, max, $max-sm){
								font-size:14px;
								@include pxtoemLineHeight(14, 18);
								padding-right:64px; 
								[dir="rtl"] & {
									padding-right:0;
									padding-left:64px;
								}
							}
							span {
								color:$color-carmine;
							}
							&:after {
								content:'';
								position:absolute;
								top:50%;
								right:0;
								background-repeat:no-repeat;
								background-position:center center;
								transform: translateY(-50%);
								[dir="rtl"] & {
									right:auto;
									left:0;
								}
							}
						}
						&.support {
							a{
								padding-right:165px; 
								[dir="rtl"] & {
									padding-right:0;
									padding-left:165px;
								}
								@include screen(custom, max, $max-sm){
									padding-right:155px;
									[dir="rtl"] & {
										padding-right:0;
										padding-left:155px;
									}
								}
							}
							// &.type-manual a:before {
							// 	content:'Manuals';
							// }
							// &.type-software a:before {
							// 	content:'Software';
							// }
							.support-type-flag {
								position: absolute;
								top:50%;
								right:22px;
								transform:translateY(-50%);
								font-size:12px;
								color:$color-nightrider;
								@include pxtoemLineHeight(12, 12);
								@include font-family($font-semibold);
								background-color:$line-lightgray;
								border-radius:100px;
								padding:3.5px 8px;
								[dir="rtl"] & {
									right:auto;
									left:22px;
								}
							}
						}
						&:not(.category) {
							a:after {
								width:12px;
								height:12px;
								background-image:url(/lg5-common-gp/images/common/icons/search-arrow.svg);
								background-size:auto 12px;
								[dir="rtl"] & {
									transform:translateY(-50%) rotate(180deg);
								}
							}
						}
						&.category {
							a {
								padding-right:32px;
								[dir="rtl"] & {
									padding-right:0;
									padding-left:32px;
								}
								&:after {
									width:16px;
									height:16px;
									background-image:url(/lg5-common-gp/images/common/icons/gnb-search-black2.svg);
									background-size:auto 16px;
								}
							}
						}
					}				
				}
				.success-seacrh{
					display:none;
					max-width:600px;
					margin:0 auto;
					padding-top:50px;
					@include screen(custom, max, $max-sm){
						max-width:none;
						padding:20px 16px 30px 16px; //LGEVN-693 
					}
					.product-page-linker {
						position:relative;
						display:flex;
						align-items: center;
						border:1px solid $line-gray;
						box-sizing:border-box;
						border-radius:10px;
						padding:14px 20px;
						@include screen(custom, max, $max-sm){
							border:none;
							border-bottom:1px solid $line-gray;
							padding:0 0 20px 0;
							border-radius:0;
						}
						&:before {
							content:'';
							position:absolute;
							top:50%;
							right:20px;
							width:24px;
							height:24px;
							background-image:url(/lg5-common-gp/images/common/icons/carousel-right.svg);
							background-repeat:no-repeat;
							background-position:center center;
							transform:translateY(-50%);
							background-size:auto 24px;
							[dir="rtl"] & {
								right:auto;
								left:20px;
								transform:translateY(-50%) rotate(180deg);
							}
							@include screen(custom, max, $max-sm){
								width:12px;
								height:12px;
								background-image:url(/lg5-common-gp/images/common/icons/search-arrow.svg);
								background-size:auto 12px;
								right:0;
								margin-top:-10px;
								[dir="rtl"] & {
									right:auto;
									left:0;
								}
							}
						}
						// [Start] LGEVN-693
						& + .product-recommend-wrap{
							margin-top: 24px;
						}
						// [End] LGEVN-693
					}
					.model-image {
						width:100px;
						min-width:100px;
						@include screen(custom, max, $max-sm){
							width:65px;
							min-width:65px;
						}
						img {
							width:100%;
						}
					}
					.model-name-box {
						padding:0 44px 0 30px;
						text-align:left;
						[dir="rtl"] & {
							padding:0 30px 0 44px;
							text-align:right;
						}
						@include screen(custom, max, $max-sm){
							padding:0 32px 0 24px;
						}
						.model-display-name {
							@include font-family($font-semibold);
							margin-bottom:5px;
						}
						.model-friendly-name {
							@include font-family($font-semibold);
							display: block;
							display: -webkit-box;
							-webkit-line-clamp: 2; 
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;	
						}
					}
					.model-link-etc {
						padding-top:38px;
						padding-bottom:24px;
						@include screen(custom, max, $max-sm){
							width:100%;
							padding:0;
							margin-top:4px;
						}
						ul {
							display:inline-block;
							margin:0 auto;
							@include screen(custom, max, $max-sm){
								width:100%;
							}
							li {
								position:relative;
								float:left;
								text-align:center;
								padding:0 20px;
								max-width:120px;
								min-width:120px;
								[dir="rtl"] & {
									float:right;
								}
								@include screen(custom, max, $max-sm){
									width:100%;
									padding:16px 0;
									text-align:left;
									max-width:none;
    							min-width:auto;
									border-bottom:1px solid $line-gray;
									[dir="rtl"] & {
										text-align:right;
									}
								}
								&:last-child:before {
									content:none;
									@include screen(custom, max, $max-sm){
										content:'';
									}
								}
								&:before {
									content:'';
									position:absolute;
									top:50%;
									right:0;
									width:1px;
									height:24px;
									background-color:$line-gray;
									transform:translateY(-50%);
									[dir="rtl"] & {
										right:auto;
										left:0;
									}
									@include screen(custom, max, $max-sm){
										width:12px;
										height:12px;
										background-color:transparent;
										background-image:url(/lg5-common-gp/images/common/icons/search-arrow.svg);
										background-repeat:no-repeat;
										[dir="rtl"] & {
											transform:translateY(-50%) rotate(180deg);
										}
									}
								}
							}
							a {
								display:inline-block;
								color:$color-nightrider;
								@include screen(custom, max, $max-sm){
									width:100%;
								}
								img {
									width: 48px;
									@include screen(custom, max, $max-sm){
										width:36px;
										height:36px;
										padding:6px;
									}
								}
								span {
									//display:inline-block;
									display: block;
									font-size:14px;
									@include pxtoemLineHeight(14, 16);
									@include font-family($font-semibold);
									padding-top:6px;
									@include screen(custom, max, $max-sm){
										display:inline-block;
										font-size:16px;
										@include pxtoemLineHeight(16, 22);
										padding-top:0;
										padding-left:8px;
										vertical-align:middle;
									}
								}
							}
						}
					}
					&.result-model-name {
						.model-name-box {
							.model-display-name {
								font-size:18px;
								color:$color-black;
								line-height: 1.3em; //LGEVN-693
								@include screen(custom, max, $max-sm){
									font-size:14px;
									line-height: 1.4em; //LGEVN-693
								}
							}
							.model-friendly-name {
								font-size:14px;
								color:$color-nightrider;
								line-height: 1.3em; //LGEVN-693
								@include screen(custom, max, $max-sm){
									font-size:12px;
									color:$color-dimgray;
									line-height: 1.4em; //LGEVN-693
								}
							}
						}
						// .model-link-etc {
						// 	ul {
						// 		li {
						// 			float:left;
						// 			width:50%;
						// 			padding-bottom:10px;
						// 			@include screen(custom, max, $max-sm){
						// 				width:100%;
						// 				padding-bottom:16px;
						// 			}
						// 		}	
						// 		a {
						// 			position:relative;
						// 			float:left;
						// 			color:$color-nightrider;
						// 			text-align:left;
						// 			[dir="rtl"] & {
						// 				float:right;
						// 				text-align:right;
						// 			}
						// 			img {
						// 				width:36px;
						// 				padding:6px;
						// 			}
						// 			span {
						// 				font-size:16px;
						// 				@include pxtoemLineHeight(16, 22);
						// 				@include font-family($font-semibold);
						// 				vertical-align:middle;
						// 				padding-left:8px;
						// 				padding-right:17px;
						// 				[dir="rtl"] & {
						// 					padding-left:17px;
						// 					padding-right:8px;
						// 				}
						// 				@include screen(custom, max, $max-sm){
						// 					padding-right:0;
						// 					vertical-align:middle;
						// 				}
						// 			}
						// 			&:before {
						// 				content:'';
						// 				position:absolute;
						// 				top:50%;
						// 				right:0;
						// 				width:12px;
						// 				height:12px;
						// 				background-image:url(/lg5-common-gp/images/common/icons/search-arrow.svg);
						// 				background-repeat:no-repeat;
						// 				transform:translateY(-50%);
						// 				[dir="rtl"] & {
						// 					right:auto;
						// 					left:0;
						// 					transform:translateY(-50%) rotate(180deg);
						// 				}
						// 			}
						// 		}
						// 	}
						// }
					}
					&.result-model-title {
						.model-name-box {
							.model-display-name {
								font-size:14px;
								color:$color-nightrider;
								line-height: 1.4em; //LGEVN-693
								@include screen(custom, max, $max-sm){
									font-size:12px;
									color:$color-dimgray;
									line-height: 1.3em; //LGEVN-693
								}
							}
							.model-friendly-name {
								font-size:18px;
								color:$color-black;
								line-height: 1.3em; //LGEVN-693
								@include screen(custom, max, $max-sm){
									font-size:14px;
									line-height: 1.4em; //LGEVN-693
								}
							}
						}
					}
					// [Start] LGEVN-693
					.product-recommend-wrap{
						//font-size: 0;
						position: relative;
						.title{
							margin: 0;
							font-size: 14px ;
							color: $color-nightrider;
							margin-bottom: 10px;
							text-transform: uppercase;
							text-align: left;
							@include screen(custom, max, $max-sm){
								display: block;
								margin-top: 20px;
							}
						}
						.items {
							display: block;
							box-sizing: border-box;
							margin: 0 -10px;
							@extend %clearfix;
							.slick-list{
								@extend %clearfix;
							}
							@include screen(custom, max, $max-sm){
								margin: 0 -6px;
							}
							.slick-list {
								width: auto;
								height: 100%;
								overflow: hidden;
							}
							.item{
								position: relative;
								float: left;
								outline: 0;
								width: 290px; 
								margin: 0 10px;
								@include screen(custom, max, $max-sm){
									width: calc(50% - 12px);
									margin: 0 6px;
								}
							}
							.slick-arrow {
								padding: 0;
								&.slick-prev,
								&.slick-next {
									position: absolute;
									top: 50%;
									width: 23px;
									height: 40px;
									transform: translate(0,-50%);
									color: transparent;
									overflow: hidden;
									line-height: 0;
									&>span{
										color: #767676;
										display: block;
										text-indent: 0;
									}
								}
								&.slick-prev {
									left: -45px;
								}
								&.slick-next {
									right: -45px;
								}
								&.slick-disabled{
									opacity: .3;
								}
							}
							.slick-dots {
								font-size: 0;
								text-align: center;
								margin: 0;
								margin-top: 12px;
								li {
									display: inline-block;
									width: 10px;
									height: 10px;
									position: relative;
									border-radius: 50%;
									margin-left: 8px;
									line-height: normal;
									border: 1px solid #767676;
									overflow: hidden;
									&:first-child {
										margin-left: 0;
									}
									a, button {
										background: $bg-white;
										display: block;
										width: 100%;
										height: 100%;
										margin: 0;
										padding: 0;
										border: 0;
										border-radius: 50%;
										color: transparent;
										text-indent: -9999em;
										&:focus {
											outline:5px auto -webkit-focus-ring-color;
										}
									}
									&.slick-active {
										border: 1px solid #a50034;
										a, button {
											background: $color-carmine;
										}
									}
									&:only-child {
										display: none;
									}
								}
							}
						}
						.product-recommend{
							display: block;
							border: 1px solid $line-gray;
							padding: 20px;
							box-sizing: border-box;
							border-radius: 10px;
							width: 100%;
							@include screen(custom, max, $max-sm){
								width: 100%;
								padding: 20px 5px;
							}
							& + .product-recommend{
								margin-left: 20px;
								@include screen(custom, max, $max-sm){
									margin-left: 12px;
								}
							}
							.model-image{
								width: 120px;
								margin: 0 auto 15px;
								@include screen(custom, max, $max-sm){
									width: 80px;
									min-width: 80px;
								}
							}
							.model-name-box{
								margin: auto;
								text-align: center;
								font-size: 14px;
								padding: 0 5px;
							}
							.model-friendly-name{
								margin-bottom: 4px;
								font-size: 14px;
								color: $color-nightrider;
								min-height: 2.6em;
								@include screen(custom, max, $max-sm){
									-webkit-line-clamp: 3;
									min-height: 4.2em;
								}
							}
							.model-display-name{
								font-size: 14px;
								color: $color-dimgray;
								margin-bottom: 0;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
					}
					// [End] LGEVN-693
				}
			}
			// 2022.02.18 수정 end
			.search-footer-area {
				padding:13px 20px;
				text-align:right;
				background:$bg-whitesmoke;
				overflow: hidden;
				.link-close {
					color: $color-nightrider;
					float: right;
					font-size:14px;
					[dir="rtl"] & {
						float:left;
					}
				}
				.results {
					float: left;
					font-size:16px;
					[dir="rtl"] & {
						float:right;
					}
				}
			}
		}
	}
	// recommended searched
	.rolling-keyword-area-wrap {
		position:relative;
		margin:20px 0 84px;
		padding-bottom:24px;
		@include screen(custom, max, $max-sm){
			margin:14px 0 0;
		}
		&:after {
			content:"";
			position: absolute;
			bottom:0;
			left:50%; margin-left:-1px;
			width:2px;
			height:14px;
			background : {
				image:url(/lg5-common-gp/images/common/icons/item-more.svg);
				repeat:no-repeat;
				position:0 0;
			}
		}
	}
	.rolling-keyword-area {
		// variable
		$rolling-item-height:31px;
		$exposal-length:3;

		text-align:center;
		overflow: hidden;
		max-height:$rolling-item-height * $exposal-length;
		@include screen(custom, max, $max-sm){
			max-height:($rolling-item-height - 1px) * $exposal-length;
			margin-top:10px;
		}
		.rolling-keyword {
			height:$rolling-item-height;
			font-size:16px;
			line-height:$rolling-item-height;
			color:$color-gray;
			transition:color 300ms ease;
			@include font-family($font-semibold);
			@include shorten;
			@include screen(custom, max, $max-sm){
				height:$rolling-item-height - 1px;
				font-size:15px;
				line-height:$rolling-item-height - 1px;
			}
			// center item
			&.highlight {color:$color-nightrider;}
			a {
				font-size:inherit;
				line-height:inherit;
				color:inherit;
			}
		}
	}
	// recently searched & try searching list
	.recenlty-keyword-list,
	.try-searching-list {
		position: relative;
		margin:0 73px;
		[dir="rtl"] & {text-align:right;}
		@include screen(custom, max, $max-sm){
			margin:0;
		}
		.head {
			font-size:16px;
			@include pxtoemLineHeight(16, 19);
			color:$color-black;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-sm){
				font-size:14px;
				@include pxtoemLineHeight(14, 18);
			}
		}
		ul {
			margin-top:10px;
			li {
				display:inline-block;
				border:1px solid $line-gray;
				border-radius:100px;
				padding:0 16px 6px;
				margin:10px 8px 0 0;
				[dir="rtl"] & {
					margin:10px 0 0 8px;
				}
			}
			a {
				font-size:13px;
				@include pxtoemLineHeight(13, 15);
				color:$color-nightrider;
				@include font-family($font-semibold);
				vertical-align:middle;
				white-space: nowrap;
				max-width: 100%;
			}
		}
	}
	// recently searched
	.recenlty-keyword-list {
		display:none;
		margin-top:50px;
		padding-bottom:30px;
		&.active {
			display:block;
		}
		@include screen(custom, max, $max-sm){
			margin:0;
			margin-top:20px;
			padding-bottom:24px;
		}
		ul {
			&.active{display:block;}
		}
		.insertview {
			display:none;
			font-size:14px;
			line-height:20px;
			color:$color-dimgray;
			margin-top:20px;
			&.active{display:block;}
		}
		.no-history {
			&:before{
				content:"";
				display:inline-block;
				vertical-align:top;
				width:20px; height:20px;
				background : {
					image : url(/lg5-common-gp/images/common/icons/clock-gray.svg);
					repeat : no-repeat;
					position : 50% 50%;
				}
				margin-right:7px;
				[dir="rtl"] & {
					margin-right:0;
					margin-left:7px;
				}
			}
			@include screen(custom, max, $max-sm){
				text-align:center;
				margin-top:22px;
			}
		}
		.disagree-cookie {
			text-align:center;
			@include screen(custom, max, $max-sm){
				margin-top:17px;
			}
			.link-text {
				color:$color-carmine;
			}
		}
		.btn-clear{
			position: absolute;
			top:0;
			right:0;
			button {
				font-size:14px;
				@include pxtoemLineHeight(14, 16);
				text-decoration:none;
				vertical-align: top;
				@include screen(custom, max, $max-sm){
					font-size:12px;
					@include pxtoemLineHeight(12, 18);
				}
			}
			[dir="rtl"] & {
				right:auto;
				left:0;
			}
		}
		ul.active ~ .btn-clear {display:block;}
		.btn-clear-item {
			width:10px;
			height:10px;
			background-image:url(/lg5-common-gp/images/common/icons/gnb-search-clear.svg);
			background-repeat:no-repeat;
			background-size:10px 10px;
			margin-left:10px;
			vertical-align:middle;
			cursor:pointer;
			font-size: 8px; // for ios
			[dir="rtl"] & {
				margin-left:0;
				margin-right:10px;
			}
		}
		& + .try-searching-list {
			// border-top:1px solid $line-gray; *remove
			padding-top:30px;
			@include screen(custom, max, $max-sm){
				padding-top:20px;
			}
		}
		// 20220304
		&.active + .try-searching-list {
			border-top:1px solid $line-gray;
		}
		// ===
	}
	// most searched
	.most-searched-board {
		$tag-height:28px;
		$tag-margin-bottom:12px;
		[dir="rtl"] & {text-align:right;}
		@include screen(custom, min, $min-md){
			padding-left:30px + 1px;
			height: auto;
			[dir="rtl"] & {
				padding-left:0;
				padding-right:30px + 1px;
			}
		}
		@include screen(custom, min, $min-lg){
			padding-left:50px + 1px;
			[dir="rtl"] & {
				padding-left:0;
				padding-right:50px + 1px;
			}
		}
		@include screen(custom, max, $max-sm){
			min-height: 115px;
			margin-top: 17px;
		}
		.stick-words {
			min-height:($tag-height + $tag-margin-bottom) * 4;
			margin-top:17px;
			@include screen(custom, max, $max-sm){
				margin-top:13px;
				min-height:auto;
				height:auto;
			}
		}
		.word-tag {
			display:inline-block;
			vertical-align:top;
			white-space: nowrap;
			overflow: hidden;
			height:$tag-height;
			background:$bg-white;
			font-size:14px;
			color:$color-nightrider;
			@include font-family($font-semibold);
			padding:2px 9px;
			border:1px solid $line-dimgray;
			border-radius:3px;
			margin-bottom: $tag-margin-bottom;
			// 20200514 START 이상현 - gnb search 추천 검색어 rtl 간격 수정
			&:not(:last-child) {
				margin-right: 6px;
				[dir="rtl"] & {
					margin-right: 0;
					margin-left: 6px;
				}
			}
			// 20200514 END
			&:focus, 
			&:hover {
				text-decoration:none;
				border:1px solid $line-black;
				color:$color-black;
			}
			&.cutoff {
				display: none;
			}
		}
	}
	// secondary view
	.register-searched-box {
		margin-top:20px;
		[dir="rtl"] & {
			text-align:right;
		}
		.stick-btn {
			margin-top:17px;
			.btn {
				display:inline-block;
				vertical-align:top;
				white-space: nowrap;
				overflow: hidden;
				background:$bg-white;
				font-size:14px;
				color:$color-carmine;
				@include font-family($font-semibold);
				padding:2px 14px;
				border:1px solid $line-carmine;
				border-radius:3px;
				line-height: 32px;
				text-transform: none;
				height: 38px;
				&:focus, 
				&:hover {
					text-decoration:none;
				}
				&:before{
					content:'';
					width:19px;
					height:20px;
					display:inline-block;
					background:url(/lg5-common-gp/images/common/icons/ico-register-product.svg) no-repeat center center;
					vertical-align: middle;
    				margin-right: 10px;
					[dir="rtl"] & {
						margin-right: 0;
						margin-left: 10px;
					}
				}
			}
		}
	}
	.similar-mapping {
		display:none;
		text-align:center;
		font-size:16px;
		@include pxtoemLineHeight(16, 20);
		color:$color-dimgray;
		@include font-family($font-semibold);
		margin-top:30px;
		@include screen(custom, max, $max-sm){
			font-size:15px;
			line-height:1;
			margin-top: 30px;
		}
		a {
			font-size:inherit;
			line-height:inherit;
			color:inherit;
			font-weight:inherit;
		}
		&.active {display:block;}
		.suggestion {
			color:$color-carmine;
		}
	}
	.result-area {
		[dir="rtl"] & {text-align:right;}
		display:none;
		&.active {
			display:block;
		}
	}
	// match product results 
	.match-results {
		position: relative;
		padding-right:27px;
		[dir="rtl"] & {
			padding-right:0;
			padding-left:27px;
		}
		// 20200309 START 이상현 - 스크롤 바 제거
		@include screen(custom, min, $min-md){
			width:calc(100% - (218px + 42px));
			&:before{
				content:"";
				width:1px;
				background-color:$line-gray;
				position: absolute;
				top:6px; bottom:0; 
				right:0;
				[dir="rtl"] & {
					right:auto;
					left:0;
				}
			}
		}
		@include screen(custom, max, $max-sm){
			padding-right:0;
			[dir="rtl"] & {
				padding-right:0;
				padding-left:0;
			}
		}
	}
	.match-keyword-count {
		font-size:16px;
		line-height:1;
		color:$color-gray;
		@include screen(custom, max, $max-sm){
			font-size:14px;
		}
		.count {color:$color-carmine;}
	}
	.match-product-list {
		margin-top:15px;
		// 20200309 START 이상현 - 스크롤 바 제거
		// @include screen(custom, min, $min-md){
		// 	max-height:292px;
		// }
		// @include screen(custom, min, $min-lg){
		// 	max-height:(120px + 35px) * 3;
		// }
		// 20200309 END
		@include screen(custom, max, $max-sm){
			margin-top:7px;
			border-bottom:1px solid $line-gray;
			padding-bottom:25px;
		}
		ul {
			@include flex;
			margin-left:-12px;
			[dir="rtl"] & {
				margin-left:0;
				margin-right:-12px;
			}
			@include screen(custom, max, $max-md){
				margin-left:0;
				[dir="rtl"] & {
					margin-left:0;
					margin-right:0;
				}
			}
		}
		.product-item {
			@include flex;
			padding: 18px 10px 20px 18px;
			border: 1px solid $line-gray;
			margin-left: 12px;
			[dir="rtl"] & {
				padding: 18px 18px 20px 10px;
				margin-left: 0;
				margin-right: 12px;
				flex-direction: row-reverse;
			}
			@include screen(custom, min, $min-lg){
				width:calc(50% - 12px);
				&:nth-child(n+3) {margin-top:12px;}
			}
			@include screen(custom, max, $max-md){
				width:100%;
				&:not(:first-child) {margin-top:12px;}
				margin-left: 0;
				[dir="rtl"] & {
					margin-left: 0;
					margin-right: 0;
				}
			}
			@include screen(custom, max, $max-sm){
				min-height:72px;
				&:not(:first-child) {margin-top:12px;}
				&:nth-child(n+4) {display:none};
			}
		}
		.product-image {
			width:70px;
			height:70px;
			@include screen(custom, max, $max-sm){
				width:65px;
				height:65px;
			}
			img {
				max-width:100%;
				max-height:100%;
				// outline:1px solid lightcoral;
				// outline-offset: -1px;
			}
		}
		.product-summary {
			min-width:156px;
			width:calc(100% - 70px);
			margin-top: -3px;
			padding-left:20px;
			[dir="rtl"] & {
				padding-left:0;
				padding-right:20px;
			}
			@include screen(custom, max, $max-sm){
				width:calc(100% - 65px);
				margin-top: -4px;
			}
			.product-page-linker {
				display:block;
				width:100%;
				@include screen(custom, min, $min-lg){
					max-width:156px;
				}
			}
			.model-display-name {
				font-size:15px;
				line-height:18px;
				color:$color-nightrider;
				@include font-family($font-semibold);
			}
			.user-friendly-name {
				font-size:14px;
				line-height:18px;
				color:$color-dimgray;
				@include font-family($font-semibold);
				display: block;
				display: -webkit-box;
				-webkit-line-clamp: 2; 
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				max-height:18px * 2;
				margin-top:4px;
				// 20200608 START 박지영 - RTL 에서 괄호로 끝나는 경우 깨지는 현상 수정
				[dir="rtl"] & {
					display:block;
				}
				// 20200608 END
			}
			.btn-area {
				margin-top:3px;
				@include screen(custom, min, $min-lg){
					margin-top:5px;
				}
				.link-text {
					[dir="rtl"] & {
						direction:rtl;
					}
					@include screen(custom, min, $min-lg){
						display:block;
					}
					@include screen(custom, max, $max-sm){
						margin-right:14px;
						[dir="rtl"] & {
							margin-right:0;
							margin-left:14px;							
						}
					}
				}
			}
		}
	}
	// categorized results
	.categorized-results {
		@include screen(custom, min, $min-md){
			width:218px;
			padding-top:4px;
		}
		@include screen(custom, max, $max-sm){
			margin-top:18px;
		}
		// 20200309 START 이상현 - 스크롤 바 제거
		.head {
			font-size:16px;
			line-height:1;
			color:$color-gray;
			// 20200506 START 이상현 - gnb search rtl 화면 오류 수정
			@include screen(custom, min, $min-md){
				padding-left:35px;
				[dir="rtl"] & {
					padding-left:0;
					padding-right:35px;
				}
			}
			@include screen(custom, max, $max-sm){
				font-size:14px;
			}
			// 20200506 end
		}
		// 20200309 end
		ul {
			margin-top:10px;
			@include screen(custom, max, $max-sm){
				margin:12px 0 0;
			}
		}
	}
	.category-link-name a {
		display:block;
		//background-color:$bg-white;
		padding:5px 0 6px;
		font-size:16px;
		line-height:24px;
		color:$color-nightrider;
		@include font-family($font-semibold);
		// 20200309 START 이상현 - 스크롤 바 제거
		@include screen(custom, min, $min-md){
			padding-left:35px;
			[dir="rtl"] & {
				padding-left:0;
				padding-right:35px;
			}
		}
		// 20200309 END 이상현 - 스크롤 바 제거
		@include screen(custom, max, $max-sm){
			padding:0;
			font-size:15px;
			line-height:30px;
		}
	}
	.cookie-permit-msg {
		padding:0 !important;
		.info-icon {
			display:none;
		}
		.info-text {
			font-size:14px;
			line-height:20px;
		}
	}
}