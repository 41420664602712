@charset 'UTF-8';
.area-globalBG {
	background-color: #f0ece4;
	.GPC0062 .visual-area {
		background-color: #fff;
	}
	.GPC0072 .text-desc {
		color: #646464;
	}
	.GPC0081 .text-desc {
		color: #646464;
	}
	.GPC0105 {
		.day-timeline {
			background-color: #fff;
		}
		.link-text {
			color: #ea1917;
		}
	}
}
