// 심플한 alert 공통 ui
// modal-simple-alert 통일 
// footer 버튼 타입 2개
// 다른 스타일 추가시 # 값으로 추가해서 사용
.modal {
	.modal-dialog.modal-simple-alert{
		padding:30px 30px 30px;
		@include screen(custom, max, $max-sm) {
			transform: translate(0, 35%);
		}
		@include screen(custom, max, 320) {
			transform: translate(0, 25%);
		}
		&.modal-md{
			padding:30px 0 30px;
		}	
		.modal-content{
			.modal-alert{
				font-size:16px;
				p{
					margin:0 0;
					padding:0 0;
					text-align: center;
				}
			}
			padding:45px 30px 40px;
			background:#fff;
			border: 1px solid #e4e4e4;
			@include screen(custom, max, $max-sm) {
				padding:45px 25px 35px;
			}
			.modal-accent-box{
				p{
					margin:0 0;
					padding:0 0;
					text-align: center;
					&.title{
						font-size:28px;
						color:$color-black;
						@include font-family($font-semibold);
						margin-bottom: 10px;
					}
					&.txt{
						font-size:16px;
						color:$color-nightrider;
						@include font-family($font-semibold);
					}
				}
			}
			.modal-body{
				padding:0 0;
				text-align:left;
				[dir="rtl"] & {
					text-align:right;
				}
				h2{
					font-size:28px;
					color:$color-black;
					@include font-family($font-semibold);
					@include screen(custom, max, $max-sm) {
						font-size:26px;
					}
				}
				p{
					padding:0 0;
					margin:15px 0 0;
					font-size:16px;
					color:$color-nightrider;
					@include font-family($font-regular);
					@include screen(custom, max, $max-sm) {
						font-size:14px;
					}
					&.align-center{text-align:center;}
				}
			}
			.modal-footer{
				padding:0 0;
				margin-top:30px;
				display:flex;
				justify-content:center;
				flex-direction: row;
				align-items: normal;
				.btn{
					margin:0 0;
					min-width:calc(50% - 5px);
					width:auto;
					@include screen(custom, max, $max-sm) {
						padding:11px 5px;
					}
					&:only-child{
						min-width:80px;
					}
				}
				&>:not(:last-child){
					margin-right: .25rem;
					[dir="rtl"] & {
						margin-right:0;
						margin-left: .25rem;
					}
				}
				&>:not(:first-child){
					margin-left: .25rem;
					[dir="rtl"] & {
						margin-left:0;
						margin-right: .25rem;
					}
				}
				&.type-vertical{
					flex-direction: column;
					align-items: center;
					.btn{
						max-width:240px;
					}
					&>:not(:last-child){
						margin-left: 0 !important;
						margin-right: 0 !important;
						margin-top:0;				
					}
					&>:not(:first-child){
						margin-left: 0 !important;
						margin-right: 0 !important;
						margin-top:5px;
					}
				}
				@include screen(custom, max, $max-sm) {
					margin-top:20px;
				}
			}
		}
	}
	// PJTJOINMEMBER-4 
	&.modal-fail{
		.modal-dialog{
			width: 400px;
			max-width: 400px;
			margin-left: -200px;
			@include screen(custom, max, $max-sm) {
				width: calc(100% - 24px);
				max-width: 100%;
				margin: 47px 12px 0;
			}
		}
		.modal-content{
			padding: 40px 30px 40px;
			.fail-box{
				&:before{
					content: '';
					width: 60px;
					height: 60px;
					display: block;
					margin: 0 auto 10px;
					background: url('/lg5-common-gp/images/common/icons/no-contents.svg') no-repeat center center;
					background-size: 60px 60px;
				}
				text-align: center;
				.fail-tit{
					font-size: 20px;
					@include font-family($font-bold);
					color: $color-nightrider;
				}
				.fail-txt{
					font-size: 16px;
					color: $color-nightrider;
					margin-top: 15px;
				}
			}
			.modal-footer{
				margin-top: 20px;
			}
			@include screen(custom, max, $max-sm) {
				padding: 30px 30px 30px;
			}
		}
	}
}