.modal {
	// plp wish-pop
	&[data-backdrop=false]{
		.modal-dialog.modal-plp-alert{
			.modal-content{
				border: 1px solid #e4e4e4;
			}
		}
	}
	&.modal-cookie-setting{
		.modal-content{
			padding: 0 0 0 !important;
		}
	}
	.modal-dialog.modal-plp,.modal-dialog.modal-plp-alert{
		.modal-content{
			padding:45px 30px 40px;
			background:#fff; 
			@include screen(custom, max, $max-sm) {
				padding:45px 25px 35px;
			}
			.modal-body{
				padding:0 0;
				text-align:left;
				[dir="rtl"] & {
					text-align:right;
				}


				
				//LGEAU-888 Start
				.title, h1, h2, h3, h4, h5, h6 {
					font-size:28px;
					color:$color-black;
					line-height: 1.2;
					@include font-family($font-semibold);
					@include screen(custom, max, $max-sm) {
						font-size:26px;
					}
				}
				//LGEAU-888 End
				p{
					padding:0 0;
					margin:15px 0 0;
					font-size:16px;
					color:$color-nightrider;
					@include font-family($font-regular);
					@include screen(custom, max, $max-sm) {
						font-size:14px;
					}
					&.align-center{text-align:center;}
				}
			}
			.modal-footer{
				padding:0 0;
				margin-top:30px;
				.btn{
					margin:0 0;
					width:100%;
					max-width: 100%;
				}
				&>:not(:last-child){
					margin-right: .25rem;
					[dir="rtl"] & {
						margin-right:0;
						margin-left: .25rem;
					}
				}
				&>:not(:first-child){
					margin-left: .25rem;
					[dir="rtl"] & {
						margin-left:0;
						margin-right: .25rem;
					}
				}
				@include screen(custom, max, $max-sm) {
					margin-top:20px;
					justify-content: center;
					flex-direction: column;
					align-items:center;
					&>:not(:last-child){margin-right: 0;margin-bottom:8px;}
					&>:not(:first-child){margin-left: 0}
				}
			}
		}
		@include screen(custom, max, $max-sm) {
			transform: translate(0, 35%);
		}
		@include screen(custom, max, 320) {
			transform: translate(0, 25%);
		}
	}
	&.modal-simple{
		.modal-content{
			padding:45px 30px 40px;
			background:#fff;
			@include screen(custom, max, $max-sm) {
				padding:45px 25px 35px;
			}
			.modal-body{
				padding:0 0;
				text-align:left;
				[dir="rtl"] & {
					text-align:right;
				}
				h2{
					font-size:28px;
					color:$color-black;
					@include font-family($font-semibold);
					@include screen(custom, max, $max-sm) {
						font-size:26px;
					}
				}
				p{
					padding:0 0;
					margin:15px 0 0;
					font-size:16px;
					color:$color-nightrider;
					@include font-family($font-regular);
					@include screen(custom, max, $max-sm) {
						font-size:14px;
					}
					&.align-center{text-align:center;}
				}
			}
			.modal-footer{
				padding:0 0;
				margin-top:30px;
				.btn{
					margin:0 0;
					width:100%;
				}
				&>:not(:last-child){
					margin-right: .25rem;
					[dir="rtl"] & {
						margin-right:0;
						margin-left: .25rem;
					}
				}
				&>:not(:first-child){
					margin-left: .25rem;
					[dir="rtl"] & {
						margin-left:0;
						margin-right: .25rem;
					}
				}
				@include screen(custom, max, $max-sm) {
					margin-top:20px;
					justify-content: center;
					flex-direction: column;
					align-items:center;
					&>:not(:last-child){margin-right: 0;margin-bottom:8px;}
					&>:not(:first-child){margin-left: 0}
				}
			}
		}
		@include screen(custom, max, $max-sm) {
			transform: translate(0, 35%);
		}
		@include screen(custom, max, 320) {
			transform: translate(0, 25%);
		}
	}
	.modal-dialog.modal-plp-alert{
		.modal-alert{
			p{
				margin:0 0;
				padding:0 0;
				text-align: center;
			}
		}
		padding:30px 30px 30px;
		.modal-footer{
			padding:0 0;
			margin-top:30px;
			display:flex;
			justify-content:center;
			flex-direction: row;
			.btn{
				margin:0 0;
				width:auto;
				@include screen(custom, max, $max-sm) {
					padding:11px 5px;
				}
			}
			&>:not(:last-child){
				margin-right: .25rem;
				[dir="rtl"] & {
					margin-right:0;
					margin-left: .25rem;
				}
			}
			&>:not(:first-child){
				margin-left: .25rem;
				[dir="rtl"] & {
					margin-left:0;
					margin-right: .25rem;
				}
			}
			@include screen(custom, max, $max-sm) {
				margin-top:20px;
			}
		}	
	}
}
// LGEGMC-1282 : start
[data-countrycode=fr] #addToCartSuccess .modal-content .modal-footer a.btn {
	min-height: 55px;
	line-height: 32px;
	@include screen(custom, max, $max-sm) {
		min-height: auto;
		line-height: 16px;
	}
}
// LGEGMC-1282 : end